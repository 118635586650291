import React from "react";
import { PlayerModel } from "../shared/models/PlayerModel";
import { Mixpanel } from "../helpers/mixpanel-helper";

const LeaderBoard = ({
  calculateNetWorth,
  players,
  setOpenProfile,
  highLightGrid,
  openWalkthroughModal,
}: {
  calculateNetWorth: (profileInfo: PlayerModel) => number;
  players: Array<PlayerModel>;
  setOpenProfile: React.Dispatch<React.SetStateAction<boolean>>;
  highLightGrid: string;
  openWalkthroughModal: boolean;
}) => {
  let newPlayers = [...players];

  const netWorths = newPlayers.sort(
    (a, b) => calculateNetWorth(b) - calculateNetWorth(a)
  );
  return (
    <ul
      style={{
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        color: "red",
        marginLeft: -30,
        justifyContent: "space-evenly",
        height: "100%",
        alignItems: "center",
      }}
    >
      {netWorths.map(
        (_item: any, index: number) =>
          index === 0 && (
            <li
              key={_item.name}
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <img
                src={require(`../assets/Avatar/${_item.name}.png`)}
                alt=""
                style={{ height: "4vw" }}
              />{" "}
              <span
                style={{
                  marginTop: "1vh",
                  fontSize: "0.8vw",
                  fontFamily: "Minecraft",
                  fontStyle: "normal",
                  color: "#FFFFFF",
                }}
              >
                #1 {_item.name}
              </span>{" "}
              <span
                style={{
                  marginTop: "1vh",
                  fontSize: "0.8vw",
                  fontFamily: " 'Urbanist', sans-serif",
                  fontStyle: "normal",
                  color: "#FFFFFF",
                }}
              >
                ${calculateNetWorth(newPlayers[index]) / 1000000}M
              </span>
            </li>
          )
      )}
      {netWorths.map(
        (_item: any, index: number) =>
          index !== 0 && (
            <li
              key={_item.name}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={require(`../assets/Avatar/${_item.name}.png`)}
                alt=""
                style={{ height: "4vw" }}
              />
              <span
                style={{
                  marginTop: "1vh",
                  fontSize: "0.8vw",
                  fontFamily: "Minecraft",
                  fontStyle: "normal",
                  color: "#FFFFFF",
                }}
              >
                {" "}
                #{index + 1} <span>{_item.name}</span>
              </span>{" "}
              <span
                style={{
                  marginTop: "1vh",
                  fontSize: "0.8vw",
                  fontFamily: " 'Urbanist', sans-serif",
                  fontStyle: "normal",
                  color: "#FFFFFF",
                }}
              >
                ${calculateNetWorth(newPlayers[index]) / 1000000}M
              </span>{" "}
            </li>
          )
      )}
      <li
        onClick={() => {
          Mixpanel.track("View Scoreboard Clicked");
          setOpenProfile(true);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <img
          src={require("../assets/ToolTips/button-buy.sell.png")}
          alt=""
          style={{ width: "6vw" }}
        />
      </li>
    </ul>
  );
};

export default LeaderBoard;
