import techBilionairePng from "../../src/assets/EventCards/_Design or Industry changes/tech billionaire tweets no bleed _ 63x88 mm.png";
import earthQuakeImage from "../../src/assets/EventCards/_Design or Industry changes/Earthquake Digital (1).png";
import acqusitionTimePng from "../../src/assets/EventCards/_Design or Industry changes/acquisition time no bleed _ 63x88 mm.png";
import bigTechMeetUpPng from "../../src/assets/EventCards/_Design or Industry changes/big tech breakup no bleed _ 63x88 mm.png";
import recordUnemploymentPng from "../../src/assets/EventCards/_Design or Industry changes/recession digital (1).png";
import redditPng from "../../src/assets/EventCards/_Design or Industry changes/internet stock tips no bleed _ 63x88 mm.png";
import bearMarketPng from "../../src/assets/EventCards/_Design or Industry changes/bear market no bleed _ 63x88 mm.png";
import greenEnergyPng from "../../src/assets/EventCards/_Design or Industry changes/green energy act no bleed _ 63x88 mm.png";
import biotechJointPng from "../../src/assets/EventCards/_Design or Industry changes/biotech joint venture no bleed _ 63x88 mm.png";
import democratsPng from "../../src/assets/EventCards/_Design or Industry changes/democrats elected digital (1).png";
import republicansPng from "../../src/assets/EventCards/_Design or Industry changes/republicans win no bleed _ 63x88 mm.png";
import heatWavePng from "../../src/assets/EventCards/_Design or Industry changes/heat wave no bleed _ 63x88 mm.png";
import stockSplitPng from "../../src/assets/EventCards/_Design or Industry changes/stock split no bleed _ 63x88 mm.png";
import pandemicPng from "../../src/assets/EventCards/_Design or Industry changes/pandemic no bleed _ 63x88 mm.png";
import whistleBlownPng from "../../src/assets/EventCards/_Design or Industry changes/whistle blown no bleed _ 63x88 mm.png";
import shortSqueezePng from "../../src/assets/EventCards/_Design or Industry changes/short squeeze no bleed _ 63x88 mm.png";
import bigUsdPurchasePng from "../../src/assets/EventCards/_Design or Industry changes/usd purchase digital.png";
import hurricanePng from "../../src/assets/EventCards/_Design or Industry changes/hurricane digital.png";
import positiveOverageOncleanTechPng from "../../src/assets/EventCards/_Design or Industry changes/positive coverage on clean tech no bleed _ 63x88 mm.png";
import bankPring5trillionPng from "../../src/assets/EventCards/_Design or Industry changes/bank prints 5 trillion digital.png";
import digitalHealthBoomingPng from "../../src/assets/EventCards/_Design or Industry changes/Digital Health Booming digital.png";
import fdaApprovesAntiAgingDrugPng from "../../src/assets/EventCards/_Design or Industry changes/FDA approves no bleed _ 63x88 mm.png";
import pdaRejectDrugPng from "../../src/assets/EventCards/_Design or Industry changes/FDA rejects no bleed _ 63x88 mm.png";
import breakingFoodRecallPng from "../../src/assets/EventCards/_Design or Industry changes/Breaking_ Food Recall digital.png";
import highShortPositionIncleanTechPng from "../../src/assets/EventCards/_Design or Industry changes/High short positions in Clean Tech digital.png";
import interestRateDown from "../../src/assets/EventCards/_Design or Industry changes/Interest Rates Down digital.png";
import interestRateUp from "../../src/assets/EventCards/_Design or Industry changes/Interest Rates up digital.png";
import fracTionalHomePng from "../../src/assets/EventCards/_Design or Industry changes/Fractional Real Estate ownership digital.png";
import cyberBreachAlertPng from "../../src/assets/EventCards/_Design or Industry changes/Cyberbreach Alert digital.png";
import worldWarPng from "../../src/assets/EventCards/_Design or Industry changes/world war ends no bleed _ 63x88 mm.png";
import founderOFleadingTechCompanyPng from "../../src/assets/EventCards/_Design or Industry changes/Founder of leading tech company passes away digital.png";
import recordHolidaySalePng from "../../src/assets/EventCards/_Design or Industry changes/Record high holiday sales due to influencers digital.png";
import BullMarketImage from "../../src/assets/EventCards/_Design or Industry changes/bull market no bleed _ 63x88 mm.png";
import FalseEarningsPng from "../../src/assets/EventCards/_Design or Industry changes/False earnings reported in Utilities digital.png";
import aiFunddingPng from '../../src/assets/EventCards/AI funding no bleed _ 63x88 mm.png';
import worldWideBatteryShortagePng from '../../src/assets/EventCards/battery shortage no bleed _ 63x88 mm.png';
import scienticBreakThroughPng from '../../src/assets/EventCards/battery tech no bleed _ 63x88 mm.png';
import boomingSectorBigDataPng from '../../src/assets/EventCards/big data no bleed _ 63x88 mm.png';
import scientificBreakthroughBlockchainPng from '../../src/assets/EventCards/blockchain breakthrough no bleed _ 63x88 mm.png';
import highCompitionINConsumerGoodsPng from '../../src/assets/EventCards/consumer goods no bleed _ 63x88 mm.png';
import scienceBreakthroughCryptoTechpng from '../../src/assets/EventCards/crypto tech breakthrough no bleed _ 63x88 mm.png';
import exPansionInnewMarketsPng from '../../src/assets/EventCards/expansion in new markets no bleed _ 63x88 mm.png';
import increaseInHouseingSupplyPng from '../../src/assets/EventCards/housing supply no bleed _ 63x88 mm.png';
import recordHighINFLATIONPng from '../../src/assets/EventCards/inflation no bleed _ 63x88 mm.png';
import bankAcusedOFMoneyLaunderingPng from '../../src/assets/EventCards/money laundering no bleed _ 63x88 mm.png';
import newFederalReserveChairPng from '../../src/assets/EventCards/new fed chairman no bleed _ 63x88 mm.png';
import nftHoldersLoseTheirShirtPng from '../../src/assets/EventCards/NFT no bleed _ 63x88 mm.png';
import politicalInstabilityPng from '../../src/assets/EventCards/political instability no bleed _ 63x88 mm.png';
import poorGuidanceInConsumerGoodsPng from '../../src/assets/EventCards/poor guidance on consumer goods no bleed _ 63x88 mm.png';
import stongGuidanceOnNetZeroPng from '../../src/assets/EventCards/zero carbon no bleed _ 63x88 mm.png';

// /////////
import { PlayerModel } from "../shared/models/PlayerModel";
import { StockModel } from "../shared/models/StockModel";

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const netWorth = (playerInfo: PlayerModel, stockPrices: any) => {
  const playerNetWorth =
    playerInfo.cash +
    playerInfo.portfolio.bonds * stockPrices.bonds.price +
    playerInfo.portfolio.commodities * stockPrices.commodities.price +
    playerInfo.portfolio.consumer_goods * stockPrices.consumer_goods.price +
    playerInfo.portfolio.finance * stockPrices.finance.price +
    playerInfo.portfolio.energy * stockPrices.energy.price +
    playerInfo.portfolio.healthcare * stockPrices.healthcare.price +
    playerInfo.portfolio.real_estate * stockPrices.real_estate.price +
    playerInfo.portfolio.technology * stockPrices.technology.price;
  return playerNetWorth;
};

export function initPlayer(name: string, type: string) {
  return {
    name,
    moves: 3,
    cash: 8000000,
    portfolio: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 0,
    },
    type,
    noAction: true,
    action_cards: [],
    total_scrore: 0,
    reduce_moves: 0,
  };
}

export const shuffleDeck = (deck: any) => {
  for (let i = deck.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = deck[i];
    deck[i] = deck[j];
    deck[j] = temp;
  }
  return deck;
};

export const initialStockPrices: StockModel = {
  technology: { name: "Technology", price: 4000000 },
  real_estate: { name: "Real Estate", price: 3000000 },
  healthcare: { name: "Healthcare", price: 2000000 },
  finance: { name: "Finance", price: 5000000 },
  energy: { name: "Energy", price: 2000000 },
  consumer_goods: { name: "Consumer Goods", price: 3000000 },
  commodities: { name: "Commodities", price: 3000000 },
  bonds: { name: "Bonds", price: 1000000 },
};

export const initialEventDeck = [
  {
    title: "Tech Billionaire Tweets",
    image: techBilionairePng,
    body: "Elon Musk tweets have moved stock shares up to 20%",
    stock_changes: {
      finance: 1,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: -1,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "EARTHQUAKE",
    image: earthQuakeImage,
    body: "Natural disasters often destabilize supply chains",
    stock_changes: {
      finance: -3,
      real_estate: -3,
      consumer_goods: 1,
      healthcare: 2,
      commodities: 2,
      energy: 2,
      bonds: -2,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "ACQUISITION TIME!",
    image: acqusitionTimePng,
    body: "Tech Giants acquiring companies across various industries",
    stock_changes: {
      finance: 5,
      real_estate: 0,
      consumer_goods: 3,
      healthcare: 3,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 4,
    },
    color: "#003566",
  },

 

  {
    title: "GREEN ENERGY ACT",
    image: greenEnergyPng,
    body: "Government Bills help boost the economy through industry spending",
    stock_changes: {
      finance: 3,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 3,
      energy: 5,
      bonds: -2,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "BIG TECH BREAKUP",
    image: bigTechMeetUpPng,
    body: "Antitrust is the regulation of monopolistic business practices",
    stock_changes: {
      finance: -4,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: -5,
    },
    color: "#003566",
  },

  {
    title: "RECORD UNEMPLOYMENT",
    image: recordUnemploymentPng,
    body: "The highest rate of U.S. unemployment was 24.9% in 1933",
    stock_changes: {
      finance: -3,
      real_estate: -2,
      consumer_goods: -5,
      healthcare: -1,
      commodities: -1,
      energy: -3,
      bonds: 0,
      technology: -3,
    },
    color: "#003566",
  },
  {
    title: "BEAR MARKET!",
    image: bearMarketPng,
    body: "The U.S. economy has weathered 14 different recessions since 1945",
    stock_changes: {
      finance: -4,
      real_estate: -2,
      consumer_goods: -2,
      healthcare: -2,
      commodities: 2,
      energy: -3,
      bonds: 1,
      technology: -5,
    },
    color: "#003566",
  },

  {
    title: "BIOTECH JOINT  VENTURE ANNOUNCED",
    image: biotechJointPng,
    body: "FAANG companies announce their BioTech joint venture",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 1,
      healthcare: 3,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 5,
    },
    color: "#003566",
  },
  {
    title: "WHISTLE BLOWN",
    image: whistleBlownPng,
    body: "In 2022, Peiter “Mudge” Zatko accused Twitter of cybersecurity negligence",
    stock_changes: {
      finance: -3,
      real_estate: 0,
      consumer_goods: -2,
      healthcare: -2,
      commodities: 0,
      energy: -2,
      bonds: 0,
      technology: -4,
    },
    color: "#003566",
  },

  {
    title: "HEAT WAVE",
    image: heatWavePng,
    body: "Heatflation inflates food prices due to heat-damaged crops and cattle",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: -3,
      healthcare:1,
      commodities: 4,
      energy: 2,
      bonds:0,
      technology: 0,
    },
    color: "#003566",
  },

  {
    title: "HURRICANE",
    image: hurricanePng,
    body: "In 2021, Hurricaine Ida cost America $65 billion",
    stock_changes: {
      finance: 0,
      real_estate: -2,
      consumer_goods: -2,
      healthcare: 0,
      commodities: -3,
      energy: -1,
      bonds: 0,
      technology: -1,
    },
    color: "#003566",
  },
  {
    title: "SHORT SQUEEZE",
    image: shortSqueezePng,
    body: "A “short squeeze” occurs when a stock which investors bet against spikes, resulting in the forced buyback of shares",
    stock_changes: {
      finance: 3,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 5,
    },
    color: "#003566",
  },
  {
    title: "STOCK SPLIT",
    image: stockSplitPng,
    body: "Companies increase the number of shares available to boost the  stock's liquidity",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 1,
      healthcare: 0,
      commodities: 0,
      energy: 1,
      bonds: 0,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "DEMOCRATS WIN",
    image: democratsPng,
    body: "Markets perform best in the second half of a presidential term",
    stock_changes: {
      finance: 2,
      real_estate: 1,
      consumer_goods: 3,
      healthcare: 2,
      commodities: 1,
      energy: 3,
      bonds: 1,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "REPUBLICANS WIN",
    image: republicansPng,
    body: "Markets perform best in the second half of a presidential term",
    stock_changes: {
      finance: 4,
      real_estate: 3,
      consumer_goods: 3,
      healthcare: 1,
      commodities: 2,
      energy: 3,
      bonds: 1,
      technology: 2,
    },
    color: "#003566",
  },

  {
    title: "BIG US DOLLAR PURCHASE",
    image: bigUsdPurchasePng,
    body: "Buying currency increases its utility and value",
    stock_changes: {
      finance: 3,
      real_estate: 1,
      consumer_goods: 1,
      healthcare: 0,
      commodities: 1,
      energy: 0,
      bonds: 2,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "POSITIVE COVERAGE ON CLEAN TECH",
    image: positiveOverageOncleanTechPng,
    body: "Media has a massive influence on market outlook",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 2,
      energy: 3,
      bonds: 0,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "BANK PRINTS $5 TRILLION VIA QUANTITIVE EASING",
    image: bankPring5trillionPng,
    body: "Quantitative Easing boosts the economy by increasing the money supply ",
    stock_changes: {
      finance: 5,
      real_estate: 3,
      consumer_goods: 5,
      healthcare: 2,
      commodities: 2,
      energy: 3,
      bonds: -2,
      technology: 5,
    },
    color: "#003566",
  },
  {
    title: "DIGITAL HEALTH BOOMING",
    image: digitalHealthBoomingPng,
    body: "Developments in technology usually leads to higher efficiency ",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 4,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 2,
    },
    color: "#003566",
  },
 
  {
    title: "FDA APPROVES ANTI-AGING DRUG",
    image: fdaApprovesAntiAgingDrugPng,
    body: "FDA approval allows companies to sell their product in market",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 2,
      healthcare: 5,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "FDA REJECTS DRUG DUE TO ILLEGAL INGREDIENTS",
    image: pdaRejectDrugPng,
    body: "On average it takes 10 years for new medicine to be discovered and sold in stores",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: -2,
      healthcare: -5,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "BREAKING: FOOD RECALL!",
    image: breakingFoodRecallPng,
    body: "Contaminated foods drive the price down for commodities such as beef, corn, poultry",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: -2,
      healthcare: 3,
      commodities: -4,
      energy: 0,
      bonds: 0,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "HIGH SHORT POSITIONS IN CLEAN TECH",
    image: highShortPositionIncleanTechPng,
    body: "Short selling is when an investor borrows a stock, sells it on the market, and expects to buy it back later for less money",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: -2,
      energy: -5,
      bonds: 0,
      technology: -3,
    },
    color: "#003566",
  },
  {
    title: "RECORD HIGH HOLIDAY SALES DUE TO INFLUENCERS",
    image: recordHolidaySalePng,
    body: "Markets perform well during the last five days of December and the first two days of the new year",
    stock_changes: {
      finance: 1,
      real_estate: 0,
      consumer_goods: 5,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "INTEREST RATES DOWN",
    image: interestRateDown,
    body: "Low interest rates mean borrowing money is cheaper, thus stimulating the economy",
    stock_changes: {
      finance: 5,
      real_estate: 5,
      consumer_goods: 1,
      healthcare: 1,
      commodities: 3,
      energy: 3,
      bonds: 2,
      technology: 3,
    },
    color: "#003566",
  },
  {
    title: "INTEREST RATES UP",
    image: interestRateUp,
    body: "High interest rates make it harder to borrow money thus hurting the economy",
    stock_changes: {
      finance: -5,
      real_estate: -5,
      consumer_goods: -1,
      healthcare: -1,
      commodities: -3,
      energy: -3,
      bonds: -2,
      technology: -3,
    },
    color: "#003566",
  },
  
  {
    title: "WORLD WAR ENDS",
    image: worldWarPng,
    body: "The Dow Jones went up 30% in the year following World War 2",
    stock_changes: {
      finance: 4,
      real_estate: 1,
      consumer_goods: 1,
      healthcare: 1,
      commodities: 2,
      energy: 1,
      bonds: 1,
      technology: 4,
    },
    color: "#003566",
  },

  {
    title: "BULL MARKET!",
    image: BullMarketImage,
    body: "A bull market is when stock prices rise by 20% after two declines of 20% each",
    stock_changes: {
      finance: 5,
      real_estate: 5,
      consumer_goods: 5,
      healthcare: 3,
      commodities: 4,
      energy: 4,
      bonds: 2,
      technology: 5,
    },
    color: "#003566",
  },
  {
    title: "CYBERBREACH ALERT",
    image: cyberBreachAlertPng,
    body: "Low interest rates mean borrowing money is cheaper, thus stimulating the economy",
    stock_changes: {
      finance: -5,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: -2,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: -5,
    },
    color: "#003566",
  },
 

  {
    title: "FALSE EARNINGS REPORTED",
    image: FalseEarningsPng,
    body: "In 2001, Enron, a large energy company, went bankrupt for fraudulent accounting practices",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 0,
    },
    color: "#003566",
  },
 
  {
    title: "FOUNDER OF LEADING TECH COMPANY PASSES AWAY",
    image: founderOFleadingTechCompanyPng,
    body: "Upon Steve Jobs passing, Apple stock went up 15% in a week",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 1,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 2,
    },
    color: "#003566",
  },
 
  {
    title: "PANDEMIC!",
    image: pandemicPng,
    body: "Markets dropped more than 30% in 4 weeks due to COVID-19",
    stock_changes: {
      finance: -5,
      real_estate: 2,
      consumer_goods: 1,
      healthcare: 4,
      commodities: -5,
      energy: -5,
      bonds: -2,
      technology: -5,
    },
    color: "#003566",
  },

  {
    title: "FRACTIONAL HOME BUYING INTRODUCED",
    image: fracTionalHomePng,
    body: "Fractional homebuying allows multiple investors to own a home",
    stock_changes: {
      finance: 0,
      real_estate: 4,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 4,
    },
    color: "#003566",
  },


  {
    title: "HOT REDDIT STOCK TIPS",
    image: redditPng,
    body: "In 2021, r/wallstreetbets posts helped increase GameStop shares 600%+",
    stock_changes: {
      finance: 3,
      real_estate: 0,
      consumer_goods: 2,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 5,
    },
    color: "#003566",
  },
  {
    title: "AI FUNDING HITTING RECORD HIGHS",
    image: aiFunddingPng,
    body: "Microsoft has collectively funded $13 billion into OpenAI, creator of ChatGPT, and DALL-E",
    stock_changes: {
      finance: 5,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 4,
    },
    color: "#003566",
  },
  {
    title: "WORLDWIDE BATTERY SHORTAGE",
    image: worldWideBatteryShortagePng,
    body: "Shortage of commodities increases prices and disrupts supply chains",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 5,
      energy: -5,
      bonds: 0,
      technology: -3,
    },
    color: "#003566",
  },
  {
    title: "NFT HOLDERS LOSE THEIR SHIRTS",
    image: nftHoldersLoseTheirShirtPng,
    body: "Losing your shirt' means losing majority of your wealth'",
    stock_changes: {
      finance: -5,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: -4,
    },
    color: "#003566",
  },
  {
    title: "SCIENTIFIC BREAKTHROUGH: BATTERY TECH",
    image: scienticBreakThroughPng,
    body: "On average, the pace of innovation in battery technology is every 7 years",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: 4,
      healthcare: 0,
      commodities: 1,
      energy: 4,
      bonds: 0,
      technology: 4,
    },
    color: "#003566",
  },
  {
    title: "POOR GUIDANCE ON CONSUMER GOODS",
    image: poorGuidanceInConsumerGoodsPng,
    body: "Guidance is information a company gives estimating it's future",
    stock_changes: {
      finance: -2,
      real_estate: 0,
      consumer_goods: -5,
      healthcare: 0,
      commodities: -1,
      energy: 0,
      bonds: 0,
      technology: -3,
    },
    color: "#003566",
  },
  {
    title: "BANK ACCUSED OF MONEY LAUNDERING",
    image: bankAcusedOFMoneyLaunderingPng,
    body: "Money laundering converts illegal money to legal tender by hiding where it came from",
    stock_changes: {
      finance: -5,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: -1,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "SCIENTIFIC BREAKTHROUGH: BLOCKCHAIN TECH",
    image: scientificBreakthroughBlockchainPng,
    body: "Smart contracts self execute with terms of agreement written into code",
    stock_changes: {
      finance: 5,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: -1,
      technology: 4,
    },
    color: "#003566",
  },
  
  {
    title: "SCIENCE BREAKTHROUGH: CRYPTO TECH",
    image: scienceBreakthroughCryptoTechpng,
    body: "Speed and security boost trading volume in crypto currencies",
    stock_changes: {
      finance: 5,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: -1,
      technology: 4,
    },
    color: "#003566",
  },
  {
    title: "NEW FEDERAL RESERVE CHAIRMAN APPOINTED",
    image: newFederalReserveChairPng,
    body: "The Federal Reserve manages money supply, sets interest rates and keeps bank safe",
    stock_changes: {
      finance: 3,
      real_estate: 2,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 1,
      energy: 0,
      bonds: 3,
      technology: 3,
    },
    color: "#003566",
  },
  {
    title: "EXPANSION IN NEW MARKETS",
    image: exPansionInnewMarketsPng,
    body: "In Africa & India, 70% of the populations are under the age of 35",
    stock_changes: {
      finance: 3,
      real_estate: 0,
      consumer_goods: 3,
      healthcare: 0,
      commodities: 2,
      energy: 0,
      bonds: 0,
      technology: 3,
    },
    color: "#003566",
  },
  {
    title: "INCREASE IN HOUSING SUPPLY",
    image: increaseInHouseingSupplyPng,
    body: "More supply stimulates economic growth, job creation and improved home affordability",
    stock_changes: {
      finance: 4,
      real_estate: 2,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 2,
      energy: 2,
      bonds: 0,
      technology: 0,
    },
    color: "#003566",
  },
  {
    title: "HIGH COMPETITION IN CONSUMER GOODS",
    image: highCompitionINConsumerGoodsPng,
    body: "More competition leads to lower prices, innovation and more choices for the consumer",
    stock_changes: {
      finance: 0,
      real_estate: 0,
      consumer_goods: -3,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: -2,
    },
    color: "#003566",
  },
  {
    title: "BOOMING SECTOR:  BIG DATA",
    image: boomingSectorBigDataPng,
    body: "By 2025, it's estimated the world will create 463 billion gigabytes of data daily",
    stock_changes: {
      finance: 3,
      real_estate: 0,
      consumer_goods: 0,
      healthcare: 0,
      commodities: 0,
      energy: 0,
      bonds: 0,
      technology: 4,
    },
    color: "#003566",
  },
  {
    title: "RECORD HIGH INFLATION!",
    image: recordHighINFLATIONPng,
    body: "In 1946, prices for goods in Hungary doubled every 15 hours on average",
    stock_changes: {
      finance: -1,
      real_estate: 2,
      consumer_goods: -3,
      healthcare: -2,
      commodities: -2,
      energy: -3,
      bonds: -1,
      technology: 2,
    },
    color: "#003566",
  },
  {
    title: "STRONG GUIDANCE FOR NET ZERO CARBON EMISSIONS",
    image: stongGuidanceOnNetZeroPng,
    body: "The G20 countries contribute 75% of the world carbon emissions",
    stock_changes: {
      finance:0,
      consumer_goods: 0,
      real_estate:0,
      healthcare: 0,
      commodities: 2,
      energy: 4,
      bonds: 0,
      technology:0,
    },
    color: "#003566",
  },
  {
    title: "POLITICAL INSTABILITY ",
    image: politicalInstabilityPng,
    body: "Uncernatinty and volatility in the stock market causes invester to be afraid to invest",
    stock_changes: {
      finance:2,
      consumer_goods: 0,
      real_estate:-1,
      healthcare: 0,
      commodities: 2,
      energy: 3,
      bonds: -2,
      technology:0,
    },
    color: "#003566",
  },
];
