import { Box, useMediaQuery } from "@mui/material";
import { useState } from "react";

const Card = ({
  card,
  index,
  playable,
}: {
  card: any;
  index: number;
  playable: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = useMediaQuery("(max-width:1000px)");

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        // width: { xs: "60px", lg: "120px" },
        // height: { xs: "90px", lg: "180px" },
        width: isMobile ? "75px" : "144.71px",
        paddingTop: "10px",

        // background: "#FFFFFF",
        // border: `4px solid ${card.color}`,
        cursor: "pointer",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: isMobile ? "-50px" : "-100px",
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        onClick={() => {
          if (playable) {
            card.action(index);
          }
        }}
        component="img"
        src={card.image}
        sx={{
          width: "100%",
          height: "100%",
          transform: isHovered ? "translateY(-100px)" : "none",
          transition: "transform 0.2s ease-in-out",
          zIndex: isHovered ? 1000 : "",
          // ":hover": {
          //   marginTop: -10,
          //   zIndex: 1000,
          // },
        }}
        alt=""
      />
    </div>
  );
};

export default Card;
