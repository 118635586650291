import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import * as Yup from "yup";
import { subscriberService } from "../../services/subscriber.services";
import Cookies from "js-cookie";
import { Mixpanel } from "../../helpers/mixpanel-helper";

const ThirdRoundEmailModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [openSuccess, setOpenSuccess] = React.useState(false);

  interface FormFields {
    email: string;
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });
  const initialValues = {
    email: "",
  };
  function onSubmit({ email }: { email: string }) {
    Mixpanel.track("User Subscribed");
    subscriberService.newSubscriber(email);
    Cookies.set("UserSubscribed", "true");
    setOpenSuccess(true);
  }

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.keyCode === 13) {
        // Enter key pressed
        const emailButton = document.getElementById("emailModalButton");
        const eventCardButton = document.getElementById(
          "endTurnButtonEventCard"
        );

        if (emailButton && eventCardButton) {
          if (emailButton.contains(event.target)) {
            emailButton.click(); // Trigger the email modal button's click event
            setOpen(false);
          } else if (eventCardButton.contains(event.target)) {
            eventCardButton.click(); // Trigger the myButton's click event
            setOpen(false);
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Cleanup: remove the event listener
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
      }}
      disableAutoFocus={true}
    >
      {openSuccess ? (
        <Paper
          variant="outlined"
          sx={{
            borderRadius: "10px",

            background: "#F2F5F9",
            width: { xs: 250, lg: 545 },
            height: { xs: 150, lg: 261 },
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <img
            src={require("../../assets/ModalPic/Frame 204.png")}
            alt=""
            style={{ width: 158, height: 181 }}
          />
          <div>
            <Typography
              style={{
                color: "#001D3D",
                fontFamily: " Urbanist",
                fontWeight: 900,
                fontSize: 32,
                lineHeight: "1em",
              }}
            >
              Thanks a million, <br /> <span> dollars</span>.
            </Typography>
            <button
              className="btn btn-primary"
              style={{
                width: 131,
                height: 37,

                background: "#FFC700",
                boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
                borderRadius: "5px",
                border: "0px",

                cursor: "pointer",
                marginTop: 20,
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              KEEP PLAYING
            </button>
          </div>
        </Paper>
      ) : (
        <Paper
          variant="outlined"
          sx={{
            position: "relative",
            background: "#F2F5F9",
            width: { xs: 300, lg: 545 },
            height: { xs: 150, lg: 261 },
            p: 0.1,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            justifyItems: "center",
            borderRadius: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "gray",
            }}
          >
            <MdClose size={"3vh"} />
          </IconButton>

          <Box
            component="img"
            src={require("../../assets/ModalPic/Group 80.png")}
            sx={{
              height: { sm: "80px", lg: "161px" },
              // width: { sm: "12px", lg: "24px" },
            }}
            alt=""
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",

              height: { xs: 100, lg: 215 },
            }}
          >
            <Typography
              sx={{
                color: "#001D3D",
                fontFamily: " Urbanist",
                fontSize: { xs: 20, lg: 40 },
                fontWeight: 900,
              }}
            >
              Enjoying?
            </Typography>
            <Typography
              sx={{
                fontFamily: " Urbanist",
                fontSize: { xs: "8px", lg: "16px" },
                fontWeight: 500,
                color: "#677585",
                lineHeight: "1.1em",
              }}
            >
              Pre-order our <br /> board game version on stockshockgame.com{" "}
              
            </Typography>

            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formikProps: FormikProps<FormFields>) => {
                  const { isValid } = formikProps;
                  return (
                    <Form style={{ marginTop: "5px", display: "flex" }}>
                      <Field
                        type="email"
                        name="email"
                        style={{
                          width: 189,
                          height: 34,
                          background: " #FFFFFF",

                          margin: "0 auto 1rem",
                          boxShadow: "none",
                          padding: "5px 10px",

                          borderRadius: 10,

                          fontWeight: 400,
                          color: "#000",
                          border: 0,
                        }}
                        placeHolder="Email"
                      />

                      <br />
                      <button
                        type="submit"
                        id="emailModalButton"
                        style={{
                          width: 83,
                          height: 37,

                          background: `${isValid ? "#FFC700" : "#C7C7C7"}`,

                          boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
                          borderRadius: "5px",
                          border: "0px",

                          cursor: "pointer",
                          marginTop: 2,
                          marginLeft: 5,
                        }}
                      >
                        ENTER
                      </button>
                    </Form>
                  );
                }}
              </Formik>

              <Typography
                sx={{
                  fontFamily: " Urbanist",
                  fontSize: { xs: "8px", lg: "16px" },
                  fontWeight: 500,
                  color: "#677585",
                }}
              >
                No junk bonds, we promise.
              </Typography>
            </div>
          </Box>
        </Paper>
      )}
    </Modal>
  );
};

export default ThirdRoundEmailModal;
