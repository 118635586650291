import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

const EventCard = () => {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        // opacity: 0.3,
        border: "1px dashed #FFFFFF",
        borderColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: " 10px",
      }}
    >
      <Paper
        sx={{
          width: { xs: "30px", lg: "87px" },
          height: { xs: "45px", lg: "122px" },
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#003566",
          border: "2px solid #FFC700",
          borderRadius: "9px",
          // textAlign: "center",
          // alignContent: "center",
          alignItems: "center",
          // justifyContent: "space-around",
          // flexDirection: "column",
          margin: { xs: "6px", lg: "15px" },
          padding: { xs: "5px", lg: "10px" },
        }}
      >
        <Typography sx={{ fontSize: { xs: 8, lg: 20 } }}>
          <b style={{ color: "#FFC700" }}>ACTION</b>
        </Typography>
      </Paper>
    </Box>
  );
};

export default EventCard;
