import "./App.css";
import HomePage from "./Components/HomePage";

import { Switch, Redirect, Route } from "react-router-dom";

function App() {
  return (
    <Switch>
      {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
      {/* <Route exact path="/login" component={LoginPage} /> */}
      <Route exact path="/" component={HomePage} />
      {/* <Route path="/" exact component={LoginPage} /> */}
      <Route render={() => <Redirect to={{ pathname: "/" }} />} />
    </Switch>
  );
}

export default App;
