import { Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MdClose } from "react-icons/md";

export default function ActionIndustrySelectionModal({
  open,
  setOpen,
  setSelectedShare,
  player,
  shares,
}: any) {
  function selectAShare(event: any) {
    setSelectedShare(event.target.id);
    setOpen(false);
  }

  const industries = Object.values(shares);

  const selectedPlayerIndustries = Object.values(player.portfolio);

  const haveSomeShares = selectedPlayerIndustries.some(
    (number: any) => number > 0
  );

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disableAutoFocus={true}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          width: { xs: 250, lg: 500 },
          borderRadius: "md",
          p: { sm: 1, lg: 5 },
          boxShadow: "lg",
          background: "#F2F5F9",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "gray",
          }}
        >
          <MdClose size={"3vh"} />
        </IconButton>
        {haveSomeShares ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              //   justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              component="h2"
              id="modal-title"
              // textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Select an Industry
            </Typography>

            <p>Which industry would you like to select?</p>

            <Grid container sx={{ paddingLeft: "50px" }}>
              {industries.map((_item: any, _index: number) =>
                player.portfolio[Object.keys(shares)[_index]] !== 0 ? (
                  <Grid xs={6}>
                    <button
                      id={
                        _item.name === "Real Estate" || "Consumer Goods"
                          ? _item.name.toLowerCase().replace(" ", "_")
                          : _item.name.toLowerCase()
                      }
                      style={{
                        width: 200,
                        height: 44,
                        background: "#FFFFFF",
                        border: "2px solid #001D3D",
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        cursor: "pointer",
                        margin: "5px",
                      }}
                      onClick={selectAShare}
                    >
                      {/* <HiOutlineDocumentDuplicate /> */}
                      <img
                        id={
                          _item.name === "Real Estate" || "Consumer Goods"
                            ? _item.name.toLowerCase().replace(" ", "_")
                            : _item.name.toLowerCase()
                        }
                        src={require(`../../assets/IndustryIcons/${_item.name.toUpperCase()}.png`)}
                        style={{ height: "24px", width: "24px" }}
                        alt=""
                      />
                      <span
                        id={
                          _item.name === "Real Estate " || "Consumer Goods "
                            ? _item.name.toLowerCase().replace(" ", "_")
                            : _item.name.toLowerCase()
                        }
                        style={{
                          paddingLeft: "10px",
                          color: "#2C2C2C",
                          fontFamily: " 'Urbanist', sans-serif",
                          fontStyle: "normal",
                          fontWeight: 700,
                          // fontSize: "12px",
                          // lineHeight: "24px",
                        }}
                      >
                        {_item.name.toUpperCase()}
                      </span>
                      <span
                        id={
                          _item.name === "Real Estate" || "Consumer Goods"
                            ? _item.name.toLowerCase().replace(" ", "_")
                            : _item.name.toLowerCase()
                        }
                        style={{ paddingLeft: "5px" }}
                      >
                        ({player.portfolio[Object.keys(shares)[_index]]})
                      </span>
                    </button>
                  </Grid>
                ) : (
                  ""
                )
              )}
            </Grid>
          </Box>
        ) : (
          <p style={{ textAlign: "center" }}>Player does not own any shares</p>
        )}
      </Paper>
    </Modal>
  );
}

//  <p style={{ textAlign: "center" }}>Player does not own any shares</p>
