import PortFolio from "./PortFolio";
import { PlayerModel } from "../shared/models/PlayerModel";
import { StockModel } from "../shared/models/StockModel";
import { Typography } from "@mui/material";

export default function BotPortfolio({
  playerInfo,
  stockPrices,
  playersHistory,
  calculateNetWorth,
  index,
  higLightGrid,
  openWalkthroughModal,
}: {
  playerInfo: PlayerModel;
  stockPrices: StockModel;
  playersHistory: Array<Array<any>>;
  calculateNetWorth: (profileInfo: PlayerModel) => number;
  index: number;
  higLightGrid: string;
  openWalkthroughModal: boolean;
}) {
  return (
    <div>
      <PortFolio
        playerInfo={playerInfo}
        stockPrices={stockPrices}
        small={true}
        higLightGrid={higLightGrid}
        openWalkthroughModal={openWalkthroughModal}
      />
      <hr
        style={{
          width: "93%",
        }}
        color="lightgrey"
      />

      <div>
        <Typography
          sx={{
            fontFamily: "'Urbanist' , sans-serif",
            fontStyle: " italic",
            fontWeight: 600,
            fontSize: { xs: "6px", lg: "12px" },
            marginLeft: "10px",
            color: "#3F403A",
            marginTop: "1vh",
          }}
        >
          NET WORTH
        </Typography>
        <ul
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            listStyle: "none",
            marginLeft: "-45px",
            color: "#3F403A",
            fontFamily: "'Urbanist' , sans-serif",
            fontStyle: " italic",
          }}
        >
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <li key={i}>
              <Typography
                sx={{
                  fontSize: { xs: "7px", lg: "14px" },
                }}
              >
                R{i}:
              </Typography>{" "}
              <br />
              {playersHistory[i - 1] && (
                <Typography
                  sx={{
                    color: "#001D3D",
                    fontSize: { xs: "7px", lg: "14px" },
                    fontWeight: 700,
                    marginLeft: "-8px",
                    fontFamily: "'Urbanist' ,  sans-serif",
                    fontStyle: "italic",
                  }}
                >
                  ${" "}
                  <span>
                    {playersHistory[i - 1][index].netWorth / 1000000}M
                  </span>
                </Typography>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
