import { Button, Modal, Paper, Slide } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";

const EventCardModal = ({
  eventCardDetails,
  open,
  setOpen,
}: {
  eventCardDetails: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0,0,0,0)",
    border: "2px solid #000",
  };
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.keyCode === 13) {
        const button = document.getElementById("eventCardButton");
        if (button) {
          button.click();
          setOpen(false);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition={false}
      disableEscapeKeyDown={false}
      disableAutoFocus={true}
      sx={{
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Slide direction="down" in={open}>
        <Box sx={style}>
          <Paper
            sx={{
              backgroundColor: "rgba(0,0,0,0)",
              cursor: "pointer",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              component="img"
              src={eventCardDetails?.image}
              sx={{
                height: { xs: 200, lg: 400 },
              }}
              alt=""
            />
            <Button
              sx={{
                width: { xs: "60px", lg: "109px" },
                height: { xs: "20px", lg: "37px" },
                background: "#FFC700",
                boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
                borderRadius: "5px",
                border: "0px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: { xs: "10px", lg: "14px" },
                fontWeight: 700,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#FFC700",
                },
              }}
              onClick={() => setOpen(false)}
              id="eventCardButton"
            >
              PUT BACK
            </Button>
          </Paper>
        </Box>
      </Slide>
    </Modal>
  );
};

export default EventCardModal;
