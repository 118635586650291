import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import EventCard from "./Cards/EventCard";
import stock from "../assets/STOCK.png";
import shock from "../assets/Shock.png";

const EventCardActionCard = ({
  roundNo,
  highLightGrid,
  openWalkthroughModal,
}: {
  roundNo: number;
  highLightGrid: string;
  openWalkthroughModal: boolean;
}) => {
  return (
    <Grid
      item
      container
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <Box
        sx={{
          width: { xs: "25px", lg: "108px" },
          height: { xs: "50px", lg: "142px" },
          background: "rgba(255, 255, 255, 0.5)",
          // opacity: 0.3,
          border: "0.929167px dashed #FFFFFF",
          borderRadius: 9,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: `${
            highLightGrid === "eventCard" && openWalkthroughModal
              ? "relative"
              : "static"
          }`,
          zIndex: `${
            highLightGrid === "eventCard" && openWalkthroughModal ? 9999 : 0
          }`,
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            // opacity: 0.3,
            border: "1px dashed #FFFFFF",
            borderColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: "10px",
          }}
        >
          <Paper
            sx={{
              width: { xs: "30px", lg: "87px" },
              height: { xs: "45px", lg: "122px" },
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#FFC300",
              border: "2px solid #001D3D",
              borderRadius: "9px",
              // textAlign: "center",
              // alignContent: "center",
              alignItems: "center",
              // justifyContent: "space-around",
              // flexDirection: "column",
              margin: { xs: "6px", lg: "15px" },
              padding: { xs: "5px", lg: "10px" },
            }}
          >
            <Typography sx={{ fontSize: { xs: 9, lg: 20 } }}>
              <b style={{ color: "#001D3D" }}>EVENT</b>
            </Typography>
          </Paper>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={stock}
          sx={{
            width: { xs: "50px", lg: "20vh" },
          }}
          alt=""
        ></Box>
        <Box
          component="img"
          src={shock}
          sx={{
            width: { xs: "70px", lg: "25vh" },
            paddingTop: { xs: "10px", lg: "20px" },
          }}
          alt=""
        ></Box>
        <Typography
          sx={{
            fontSize: { xs: "10px", lg: "20px" },
            marginTop: { xs: "10px", lg: "20px" },
            color: "white",
          }}
        >
          {roundNo < 7 ? `ROUND: ${roundNo}` : "GAME OVER"}
        </Typography>
      </div>
      <Box
        sx={{
          width: { xs: "25px", lg: "108px" },
          height: { xs: "50px", lg: "142px" },
          background: "rgba(255, 255, 255, 0.5)",
          // opacity: 0.3,
          border: "0.929167px dashed #FFFFFF",
          borderRadius: 9,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EventCard />
      </Box>
    </Grid>
  );
};

export default EventCardActionCard;
