import { Modal, Slide, Paper, Box } from "@mui/material";
import { PlayerModel } from "../../shared/models/PlayerModel";
import { StockModel } from "../../shared/models/StockModel";
import BotPortfolio from "../BotPortfolio";

const ProfileModal = ({
  open,
  setOpen,
  playerInfo1,
  playerInfo2,
  playerInfo3,
  calculateNetWorth,
  stockPrices,
  playersHistory,
  higLightGrid,
  openWalkthroughModal,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  playerInfo1: PlayerModel;
  playerInfo2: PlayerModel;
  playerInfo3: PlayerModel;
  calculateNetWorth: (profileInfo: PlayerModel) => number;
  stockPrices: StockModel;
  playersHistory: Array<Array<PlayerModel>>;
  higLightGrid: string;
  openWalkthroughModal: boolean;
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      disableAutoFocus={true}
    >
      <Slide direction="down" in={open}>
        <Paper
          sx={{
            display: "flex",
            backgroundColor: "rgba(0,0,0,0)",
          }}
        >
          <Box
            sx={{
              height: { xs: 340, lg: 600 },
              background: "#F5F9F2",
              boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
            }}
          >
            <BotPortfolio
              playerInfo={playerInfo1}
              stockPrices={stockPrices}
              playersHistory={playersHistory}
              calculateNetWorth={calculateNetWorth}
              index={1}
              higLightGrid={higLightGrid}
              openWalkthroughModal={openWalkthroughModal}
            />
          </Box>
          <Box
            sx={{
              marginX: 2,
              height: { xs: 340, lg: 600 },
              borderRadius: " 20px",
              background: "#F9F7F2",
              boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
            }}
          >
            <BotPortfolio
              playerInfo={playerInfo2}
              stockPrices={stockPrices}
              playersHistory={playersHistory}
              calculateNetWorth={calculateNetWorth}
              index={2}
              higLightGrid={higLightGrid}
              openWalkthroughModal={openWalkthroughModal}
            />
          </Box>
          <Box
            sx={{
              height: { xs: 340, lg: 600 },
              borderRadius: " 20px",
              background: "#F9F2F2",
              boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
            }}
          >
            <BotPortfolio
              playerInfo={playerInfo3}
              stockPrices={stockPrices}
              playersHistory={playersHistory}
              calculateNetWorth={calculateNetWorth}
              index={3}
              higLightGrid={higLightGrid}
              openWalkthroughModal={openWalkthroughModal}
            />
          </Box>
        </Paper>
      </Slide>
    </Modal>
  );
};

export default ProfileModal;
