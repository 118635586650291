import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import React from "react";
import { Mixpanel } from "../../helpers/mixpanel-helper";

const RuleBookModal = ({
  openRuleBookModal,
  setRuleBookModal,
  setOpenWalkthroughModal,
  setSlideToOpen,
}: {
  openRuleBookModal: boolean;
  setRuleBookModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenWalkthroughModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSlideToOpen: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <Dialog
      open={openRuleBookModal}
      onClose={() => setRuleBookModal(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent>
        <DialogContentText
          sx={{ color: "black" }}
          id="scroll-dialog-description"
        >
          <DialogTitle
            id="scroll-dialog-title"
            onClick={() => {
              Mixpanel.track("Repeat Tutorial Clicked");
              Cookies.set("WebsiteVisited", "false");
              window.location.reload();
            }}
            sx={{ cursor: "pointer", textAlign: "center", color: "primary" }}
          >
            <Button variant="outlined">Repeat Tutorial</Button>
          </DialogTitle>
          <Typography variant="h5" style={{ color: "black" }}>
            <b>Objective</b>
          </Typography>
          <Typography variant="subtitle1">
            Each player’s goal is to become the richest person after 6 rounds by
            strategically managing their investments through purchasing and
            selling shares in various industries.
          </Typography>
          <Typography variant="h5" sx={{ color: "black", marginTop: "15px" }}>
            <b>Setup</b>
          </Typography>
          <Typography variant="subtitle1">
            1. Shuffle each deck and deal 4 action cards to each player.
          </Typography>
          <Typography variant="subtitle1">
            2. All players start with $8M in cash and should write down the
            beginning prices of each industry on their scorecards:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <ul>
              <li>
                <Typography variant="subtitle1">Healthcare: $2M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Energy: $2M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Real Estate: $3M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Consumer Goods: $3M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Commodities: $3M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Finance: $5M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Technology: $4M</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Bonds: $1M</Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1">
            3. Youngest player starts the first round as Player One.
          </Typography>

          <Typography variant="h5" sx={{ color: "black", marginTop: "15px" }}>
            <b>How to Play</b>
          </Typography>
          <Typography variant="subtitle1">
            1. Game Start: Each round starts by Player One rolling the die and
            all players additionally get that amount of million(s) in cash to
            start the round (i.e. Die rolled 2 = $2M).
          </Typography>
          <Typography variant="subtitle1">
            2. Each player picks up one action card at the start of their turn
            and can play up to three moves, a move can be any of the following:{" "}
          </Typography>
          <Typography variant="subtitle1">
            <ul style={{ listStyle: "none" }}>
              <li>
                <Typography variant="subtitle1">
                  a. Buying any number of shares in one industry; player must
                  add the purchased number of shares and subtract cash on their
                  scorecard.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  b. Selling any number of shares in one industry; player must
                  subtract the sold number of shares and add cash on their
                  scorecard.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  c. or using one action card. A used action card is put into a
                  discard pile. If a player runs out of action cards, they
                  pickup 4 action cards on their next turn.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  d. <b>Keep in mind:</b> A buy or sell in one industry counts
                  as one move (i.e. Buying in Healthcare and Selling in
                  Technology counts as two moves)
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1">
            3. Play continues counter clockwise. Once all players are done,
            Player One picks up and plays the top event card. All players change
            industry prices on their scorecards based on the event card price
            effects. Industry prices cannot go below $1M.
          </Typography>
          <Typography variant="subtitle1">
            4. Each round the die moves to the right and that player starts the
            next round as Player One.
          </Typography>
          <Typography variant="subtitle1">
            5. Game End: After the 8th event card is picked up and prices
            change, players tally all their own share values in each industry
            (price x # of shares owned) plus their cash. The winner is the
            person with the highest net worth (score).
          </Typography>

          <Typography variant="h5" sx={{ color: "black", marginTop: "15px" }}>
            <b> Description of Cards</b>
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  <Typography variant="subtitle1" sx={{ color: "black" }}>
                    {" "}
                    <b> Event Cards -</b>
                    {
                      " world events that affect the price of certain industries. These are played at the end of every round, affecting all players equally. Industry prices cannot go below $1M (i.e. if the current price is $3M and the event decreased the price by $5M, the industry cannot be -$2M, there is a price floor at $1M)."
                    }
                  </Typography>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Typography variant="body1" sx={{ color: "black" }}>
                    <b> Action Cards -</b>
                    {
                      "various actions that can be played against opponents or for a player’s benefit. "
                    }
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        <b> See the Futures -</b> This allows a player to
                        privately view the next event card and complete their
                        remaining moves accordingly. The player must place the
                        card back on top of the event deck.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Stock Block -</b> Can be played anytime, even if it
                        is not their turn, to negate the effect of another
                        player’s action card or move. This does not count as a
                        turn.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Stock Dividend -</b> This allows the player to
                        receive a certain number of shares directly on their
                        scoreboards for free.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Greed is Good -</b> Allows the player to pickup 2
                        action cards.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b>Robinhood - </b>This allows the player to steal half
                        of any opponent’s shares in one industry which is
                        directly transferred to their scoreboard and removed
                        from their opponent’s scoreboard (number of shares are
                        rounded up, i.e. 11 shares = 6 shares).
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Frozen Asset -</b> This allows the player to pick an
                        opponent to skip one of their moves on their next turn.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Frozen Assets - </b>This allows the player to pick
                        an opponent to skip two of their moves on their next
                        turn.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Insider Trading -</b> This allows the player to pick
                        an opponent to lose half of their shares in one industry
                        which is erased from the opponent’s scoreboard (number
                        of shares are rounded up, i.e. 11 shares = 6 shares).
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Year End Bonuses -</b> This card allows all players
                        to collect cash according to their hierarchy, Player 1
                        is considered to be the player currently with the die,
                        Player 2 is clockwise to them and so on.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Nation Donation -</b> This allows the player to
                        steal $5M cash from an opponent. If they have less than
                        $5M cash, you may steal only that amount.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <b> Stock Safety- </b>This card allows the player to
                        protect themselves from any action cards used against
                        them by their opponents for the remainder of the current
                        round only. Once the event card is played, this card is
                        discarded.
                      </Typography>
                    </li>
                  </ul>
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" sx={{ color: "black", marginTop: "15px" }}>
            <b>FAQ</b>
          </Typography>
          <Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Can you use a “Stock Block” against another “Stock Block”? -
                  Yes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Does “Stock Block” count as a turn? - No.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Can I buy half shares or spend half a million? - No, all
                  purchases or sales must be made in increments of $1m and/or 1
                  whole share.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Can I save my action cards for future turns? - Yes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  What’s the lowest price for any industry? - $1m, no industry
                  can go below that price irrespective of the event card affect
                  that round.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  We ran out of action cards, now what? - Shuffle and re-use the
                  discard pile.
                </Typography>
              </li>
            </ul>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setRuleBookModal(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RuleBookModal;
