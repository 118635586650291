import React from "react";
import { PlayerModel } from "../../shared/models/PlayerModel";
import { Modal, Paper } from "@mui/material";
import leftBand from "../../assets/Group 62.png";
import rightBand from "../../assets/Group 61.png";
import startsLeft from "../../assets/Group 63.png";
import startsRight from "../../assets/Group 64.png";
import CupIcon from "../../assets/Icons (1).png";

const ResultModal = ({
  calculateNetWorth,
  players,
  setStartTooltip,
}: {
  calculateNetWorth: (profileInfo: PlayerModel) => number;
  players: Array<PlayerModel>;
  setStartTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = React.useState(true);

  const getWinner = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: -60,
        }}
      >
        <p
          style={{
            color: "#FFC700",
            fontSize: "36px",
            fontWeight: 900,
            textTransform: "uppercase",
            fontFamily: "'Urbanist' , sans-serif",
            WebkitTextStroke: "0.6px #000000",
          }}
        >
          Winner
        </p>
      </div>
    );
  };

  let newPlayers = [...players];

  const netWorths = newPlayers.sort(
    (a, b) => calculateNetWorth(b) - calculateNetWorth(a)
  );

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableAutoFocus={true}
    >
      <Paper
        sx={{
          width: 509,
          height: "auto",

          background: " #F2F5F9",
          boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
          borderRadius: "20px",
          display: "flex",

          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
            paddingLeft: "50px",
          }}
        >
          {getWinner()}
          {netWorths.map(
            (_item: any, index: number) =>
              index === 0 && (
                <div
                  key={_item.name}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "" }}>
                    <img
                      src={startsLeft}
                      style={{ width: "150px", height: "170px" }}
                      alt=""
                    />
                    <div style={{ marginTop: "65px", display: "flex" }}>
                      <img
                        src={leftBand}
                        style={{
                          width: "30px",
                          height: "15px",
                          marginTop: "20px",
                        }}
                        alt=""
                      />
                      <img
                        src={require(`../../assets/Avatar/` +
                          _item.name +
                          ".png")}
                        alt=""
                        style={{
                          width: "80px",
                          height: "80px",
                          marginTop: "-20px",
                        }}
                      />
                      <img
                        src={rightBand}
                        alt=""
                        style={{
                          width: "30px",
                          height: "15px",
                          marginTop: "20px",
                        }}
                      />
                    </div>
                    <img
                      src={startsRight}
                      style={{ width: "150px", height: "170px" }}
                      alt=""
                    />
                  </div>

                  <img
                    src={CupIcon}
                    style={{
                      height: "30px",
                      width: "30px",
                      position: "absolute",
                      marginTop: "35px",
                    }}
                    alt=""
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "#3F403A",
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "-25px",
                      }}
                    >
                      {_item.name}
                    </p>

                    <p
                      style={{
                        color: "#3F403A",
                        fontSize: "14px",
                        fontWeight: 700,
                        marginTop: "-14px",
                      }}
                    >
                      NET WORTH: <span style={{ color: "#001D3D" }}>$</span>{" "}
                      <span
                        style={{
                          color: "#001D3D",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {calculateNetWorth(newPlayers[index]) / 1000000}M
                      </span>
                    </p>
                  </div>
                </div>
              )
          )}

          {netWorths.map(
            (_item: any, index: number) =>
              index !== 0 && (
                <div
                  key={_item.name}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ display: "flex" }}>
                    <p
                      style={{
                        color: "#001D3D",
                        fontSize: "16px",
                        fontWeight: 700,
                        marginTop: "20px",
                      }}
                    >
                      {(index === 1 && "2nd") ||
                        (index === 2 && "3rd") ||
                        (index === 3 && "4th")}
                    </p>{" "}
                    <img
                      src={require(`../../assets/Avatar/` +
                        _item.name +
                        ".png")}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                        marginTop: "5px",
                        marginLeft: "10px",
                      }}
                    />{" "}
                    <p
                      style={{
                        color: "#3F403A",
                        fontWeight: 700,
                        fontSize: "20px",

                        marginLeft: "20px",
                      }}
                    >
                      {_item.name}
                    </p>{" "}
                  </p>
                  <p
                    style={{
                      color: "#3F403A",
                      fontSize: "14px",
                      fontWeight: 700,
                      marginTop: "40px",
                    }}
                  >
                    NET WORTH: <span style={{ color: "#001D3D" }}>$</span>{" "}
                    <span
                      style={{
                        color: "#001D3D",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      {calculateNetWorth(newPlayers[index]) / 1000000}M
                    </span>
                  </p>
                </div>
              )
          )}
        </div>

        <button
          onClick={() => {
            setOpen(false);
            window.location.reload();
            setStartTooltip(true);
          }}
          style={{
            width: " 119px",
            height: "37px",

            background: "#FFC700",
            boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
            borderRadius: "5px",
            border: "0px",
            marginTop: "20px",
            marginLeft: "37%",
            marginBottom: "20px",
            fontWeight: 700,
            cursor: "pointer",
          }}
        >
          NEW GAME
        </button>
      </Paper>
    </Modal>
  );
};

export default ResultModal;
