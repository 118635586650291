import { BehaviorSubject } from "rxjs";

import { fetchWrapper } from "../helpers";
import axios from "axios";
import Cookies from "js-cookie";
import { config } from "../shared/constants";
import { Mixpanel } from "../helpers/mixpanel-helper";



export const userSubject: any = new BehaviorSubject(
  JSON.parse(localStorage.getItem("user")!)
);

const baseUrl = `${config.apiUrl}/api/users`;

export const accountService = {
  getProfileByUsername,
  sendMail,
  nearbyUsers,
  signin,
  socialSignin,
  logout,
  refreshToken,
  register,
  checkUsername,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  changePassword,
  getAll,
  getById,
  create,
  update,
  deleteUser,
  uploadImage,
  getNewNotifications,
  getNewChats,
  noNewChat,
  noNewNotification,
  changeBlockStatus,
  changeFirstTime,
  checkResetCode,
  delete: _delete,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
};

function changeBlockStatus(userId: string, status: boolean) {
  return fetchWrapper
    .put(`${baseUrl}/block-status`, { userId, status })
    .then((user) => {
      user = { ...userSubject.value, ...user };
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      return user;
    })
    .catch((error) => console.log(error));
}

function getProfileByUsername(username: string) {
  return fetchWrapper.get(`${baseUrl}/getProfile/${username}`);
}

function sendMail() {
  return fetchWrapper.get(`${baseUrl}/sendVerificationMail`);
}

async function noNewNotification() {
  return fetchWrapper.get(`${baseUrl}/noNewNotification`);
}

async function getNewNotifications() {
  return fetchWrapper.get(`${baseUrl}/newNotifications`);
}

async function noNewChat() {
  return fetchWrapper.get(`${baseUrl}/noNewChat`);
}

async function getNewChats() {
  return fetchWrapper.get(`${baseUrl}/newChats`);
}

function nearbyUsers(region: {
  name: any;
  countryCode: any;
  stateCode: any;
  latitude: any;
  longitude: any;
}) {
  return fetchWrapper.get(
    `${baseUrl}/nearbyUsers?region=${JSON.stringify(region)}`
  );
}

function uploadImage(data: any, userId: string) {
  return fetchWrapper.postFormData(
    `${baseUrl}/profile-pic?filename=${userId}&username=profile-pics`,
    data
  );
}

function deleteUser(userId: string) {
  return fetchWrapper.delete(`${baseUrl}/delete/${userId}`);
}

function socialSignin(provider: string, token: string) {
  return fetchWrapper
    .post(`${baseUrl}/socialauth`, { provider, token })
    .then((user) => {
  
      Mixpanel.track("Signed In", {
        "Signin Type": "Social",
      });
      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    });
}

function signin(email: string, password: string) {
  return fetchWrapper
    .post(`${baseUrl}/login`, { email, password })
    .then((user) => {
    
      Mixpanel.track("Signed In", {
        "Signin Type": "Email",
      });
      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    });
}

async function logout() {
  Mixpanel.track("Logged Out");

  stopRefreshTokenTimer();
  localStorage.removeItem("user");
  localStorage.removeItem("tokenexpiry");
  userSubject.next(null);
  Cookies.remove("refreshToken");
  Cookies.remove("jwtToken");
  return "true";
}

function refreshToken() {

  return fetchWrapper
    .post(`${baseUrl}/refresh-token`, {})
    .then((user) => {

      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    })
    .catch((error) => {
      console.log(error);
      Cookies.set("refreshtokenavailable", "no", { expires: 7 });
    });
}

function register(body: any) {
  return fetchWrapper.post(baseUrl, body).then((user) => {

    Mixpanel.track("Signed Up", {
      "Signup Type": "Email",
    });
    let currDate = new Date();
    currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
    localStorage.setItem("tokenexpiry", "" + currDate.getTime());
    localStorage.setItem("user", JSON.stringify(user));
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

async function checkUsername(username: string) {
  try {
    return await axios.get(`${baseUrl}/checkUsername`, {
      params: {
        username: username,
      },
    });
  } catch (error) {
    return error;
  }
}

function verifyEmail(token: any) {
  return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email: string) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}

function validateResetToken(token: string | (string | null)[] | null) {
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }: any) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, {
    token,
    password,
    confirmPassword,
  });
}

function changePassword(oldPassword: string, newPassword: string) {
  return fetchWrapper.post(`${baseUrl}/change-password`, {
    oldPassword,
    newPassword,
  });
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}/all`);
}

function getById(id: string) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(body: any) {
  return fetchWrapper.post(baseUrl, body);
}

function update(body: any) {
  return fetchWrapper
    .put(baseUrl, body)
    .then((user) => {
     
      user = { ...userSubject.value, ...user };
    
      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      // }
      return user;
    })
    .catch((error) => console.log(error));
}

function _delete(id: string) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    if (userSubject.value && id === userSubject.value.id) {
      logout();
    }
    return x;
  });
}

// helper functions

let refreshTokenTimeout: NodeJS.Timeout;

function startRefreshTokenTimer() {
  if (userSubject.value) {
  
    const timeout = 13 * 60 * 1000;
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
  }
}

function checkResetCode(body: any) {
  return fetchWrapper.post(`${baseUrl}/check-reset-code`, { body });
}

function changeFirstTime(body: any) {
  return fetchWrapper
    .put(`${baseUrl}/change-firstTime`, { body })
    .then((user) => {
      // update stored user if the logged in user updated their own record
      // if (userSubject.value && user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user };
   

      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      // }
      return user;
    })
    .catch((error) => console.log(error));
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

