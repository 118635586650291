import { Box } from "@mui/material";

export default function Dice({
  isRolling,
  face,
  onClick,
}: {
  isRolling: boolean;
  face: number;
  onClick?: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      component="img"
      src={
        isRolling
          ? require("../assets/dice/rolling.gif")
          : require(`../assets/dice/dice${face}.png`)
      }
      alt={`Dice face ${face}`}
      sx={{
        width: { xs: "25px", lg: "5vh" },
        height: { xs: "25px", lg: "5vh" },
        cursor: onClick !== null ? "pointer" : "default",
      }}
    />
  );
}
