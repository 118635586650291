import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";

export default function BasicModal({
  open,
  setOpen,
  setQtyModalOpen,
  setSelectedShare,
  modaltype,
  player,
  shares,
  setOpenWalkthroughModal,
}: any) {
  function selectAShare(event: any) {
    setSelectedShare(event.target.id);
    setOpen(false);
    setQtyModalOpen(true);
    setOpenWalkthroughModal(false);
  }

  const industries = Object.values(shares);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => {
        setOpen(false);
        setOpenWalkthroughModal(false);
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disableAutoFocus={true}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          width: { xs: 250, lg: 500 },
          borderRadius: "md",
          p: { sm: 1, lg: 5 },
          boxShadow: "lg",
          background: "#F2F5F9",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() =>{
            setOpen(false);
            setOpenWalkthroughModal(false);
          } }
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "gray",
          }}
        >
          <MdClose size={"3vh"} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: 700, fontSize: { xs: "10px", lg: "20px" } }}
            id="modal-title"
            fontWeight="lg"
            mb={1}
          >
            {modaltype === "BUY"
              ? "Buy"
              : " " || modaltype === "SELL"
              ? "Sell"
              : ""}
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: { xs: "8px", lg: "16px" } }}
          >
            Which industry would you like to{" "}
            {modaltype === "BUY" ? "buy shares in?" : "sell shares from?"}
          </Typography>
          <Grid
            container
            sx={{ marginTop: "1vh", paddingLeft: { xs: "10px", lg: "50px" } }}
          >
            {industries.map((_item: any, _index: number) =>
              (modaltype === "BUY" && player.cash >= _item.price) ||
              (modaltype === "SELL" &&
                player.portfolio[Object.keys(shares)[_index]] !== 0) ? (
                <Grid xs={6} key={_item.name}>
                  <Button
                    id={
                      _item.name === "Real Estate" || "Consumer Goods"
                        ? _item.name.toLowerCase().replace(" ", "_")
                        : _item.name.toLowerCase()
                    }
                    sx={{
                      width: { sm: 100, lg: 200 },
                      height: { sm: 22, lg: 44 },
                      background: "#FFFFFF",
                      border: {
                        xs: "1px solid #001D3D",
                        lg: "2px solid #001D3D",
                      },
                      borderRadius: { sm: 1.5, lg: 3 },
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: "5px",
                    }}
                    onClick={selectAShare}
                  >
                    <Box
                      id={
                        _item.name === "Real Estate" || "Consumer Goods"
                          ? _item.name.toLowerCase().replace(" ", "_")
                          : _item.name.toLowerCase()
                      }
                      component="img"
                      src={require(`../../assets/IndustryIcons/${_item.name.toUpperCase()}.png`)}
                      sx={{
                        maxWidth: 30,
                        height: { sm: "12px", lg: "24px" },
                        width: { sm: "12px", lg: "24px" },
                      }}
                      alt=""
                    />
                    <Typography
                      id={
                        _item.name === "Real Estate " || "Consumer Goods "
                          ? _item.name.toLowerCase().replace(" ", "_")
                          : _item.name.toLowerCase()
                      }
                      sx={{
                        paddingLeft: { xs: "5px", lg: "10px" },
                        color: "#2C2C2C",
                        fontFamily: " 'Urbanist', sans-serif",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: { xs: "7px", lg: "16px" },
                      }}
                    >
                      {_item.name.toUpperCase()}
                    </Typography>
                    <Typography
                      id={
                        _item.name === "Real Estate" || "Consumer Goods"
                          ? _item.name.toLowerCase().replace(" ", "_")
                          : _item.name.toLowerCase()
                      }
                      sx={{
                        paddingLeft: "5px",
                        fontSize: { xs: "6px", lg: "12px" },
                      }}
                    >
                      (
                      {modaltype === "BUY"
                        ? "$" +
                          shares[Object.keys(shares)[_index]].price / 1000000 +
                          "M"
                        : player.portfolio[Object.keys(shares)[_index]]}
                      )
                    </Typography>
                  </Button>
                </Grid>
              ) : (
                ""
              )
            )}
          </Grid>

          {modaltype === "BUY" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { sm: "8px", lg: "16px" },
                marginTop: "1vh",
              }}
            >
              Your Buying Power : <span style={{ paddingRight: "3px" }}>$</span>
              {player.cash / 1000000}M
            </Typography>
          )}
        </Box>
      </Paper>
    </Modal>
  );
}
