import { Modal, Paper, Slide } from "@mui/material";
import React from "react";

const TurnOverModalForPlayer = ({ open, setTurnOverForPlayer }: any) => {
  // close modal after 1 second
  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setTurnOverForPlayer(false);
      }, 1000);
    }
  }, [open, setTurnOverForPlayer]);
  return (
    <Modal
      aria-labelledby="top-modal"
      aria-describedby="top-modal-description"
      closeAfterTransition={true}
      disableEscapeKeyDown={true}
      disableAutoFocus={true}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      open={open}
      onClose={() => {
        setTurnOverForPlayer(false);
      }}
    >
      <Paper
        sx={{
          backgroundColor: "rgba(0,0,0,0)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Slide direction="left" in={open}>
          <div>
            <h1 style={{ color: "white" }}>TURN OVER </h1>
          </div>
        </Slide>
      </Paper>
    </Modal>
  );
};

export default TurnOverModalForPlayer;
