import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

export const subscriberService = {
  newSubscriber,
};

const baseUrl = `${config.apiUrl}/api`;

function newSubscriber(email: string) {
  return fetchWrapper.post(`${baseUrl}/new-subscriber`, { email });
}
