import { Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function StockDividendModal({
  open,
  setOpen,
  setStockDividendIndustry,
  shares,
}: any) {
  function selectAShare(event: any) {
    setStockDividendIndustry(event.target.id);

    setOpen(false);
  }

  const industries = Object.values(shares);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disableAutoFocus={true}
    >
      <Paper
        variant="outlined"
        sx={{
          width: 500,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",

            alignItems: "center",
          }}
        >
          <Typography component="h2" id="modal-title" fontWeight="lg" mb={1}>
            Select an Industry
          </Typography>
          <p>Which industry would you like to select?</p>
          <Grid container sx={{ paddingLeft: "50px" }}>
            {industries.map((_item: any, _index: number) => (
              <Grid xs={6} key={_item.name}>
                <button
                  id={
                    _item.name === "Real Estate" || "Consumer Goods"
                      ? _item.name.toLowerCase().replace(" ", "_")
                      : _item.name.toLowerCase()
                  }
                  style={{
                    width: 200,
                    height: 44,
                    background: "#FFFFFF",
                    border: "2px solid #001D3D",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: "5px",
                  }}
                  onClick={selectAShare}
                >
                  {/* <HiOutlineDocumentDuplicate /> */}
                  <img
                    id={
                      _item.name === "Real Estate" || "Consumer Goods"
                        ? _item.name.toLowerCase().replace(" ", "_")
                        : _item.name.toLowerCase()
                    }
                    src={require(`../../assets/IndustryIcons/${_item.name.toUpperCase()}.png`)}
                    style={{ height: "24px", width: "24px" }}
                    alt=""
                  />
                  <span
                    id={
                      _item.name === "Real Estate " || "Consumer Goods "
                        ? _item.name.toLowerCase().replace(" ", "_")
                        : _item.name.toLowerCase()
                    }
                    style={{
                      paddingLeft: "10px",
                      color: "#2C2C2C",
                      fontFamily: " 'Urbanist', sans-serif",
                      fontStyle: "normal",
                      fontWeight: 700,
                      // fontSize: "12px",
                      // lineHeight: "24px",
                    }}
                  >
                    {_item.name.toUpperCase()}
                  </span>
                  {/* <span
                    id={
                      _item.name === "Real Estate" || "Consumer Goods"
                        ? _item.name.toLowerCase().replace(" ", "_")
                        : _item.name.toLowerCase()
                    }
                    style={{ paddingLeft: "5px" }}
                  ></span> */}
                </button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
}
