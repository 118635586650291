import { Box, Button, Modal, Paper, Slide, Typography } from "@mui/material";
import { useEffect } from "react";

export default function PopUpActionCardModal({
  open,
  setOpen,
  playerHasActionCard,
  PopupActionCardDetails,
  player,
  setPlayStockBlockBool,
  playStockBlock,
  setPickUpActionCard,
}: any) {
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.keyCode === 13) {
        // Enter key pressed
        const button = document.getElementById("myButton");
        if (button) {
          button.click(); // Trigger the button's click event
          setOpen(false);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Cleanup: remove the event listener
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <Modal
      aria-labelledby="top-modal"
      aria-describedby="top-modal-description"
      closeAfterTransition={false}
      disableEscapeKeyDown={false}
      open={open}
      onClose={() => setOpen(false)}
      id="action-card-modal"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      disableAutoFocus={true}
    >
      <Slide direction="down" in={open}>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "rgba(0,0,0,0)",
            border: "0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Urbanist , sans-serif",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: { sm: "16px", lg: "32px" },
              color: "#FFFFFF",
              marginBottom: "13px",
            }}
          >
            {player.type === "bot" ? `${player.name} Played` : `You Picked Up`}
          </Typography>

          <Box
            component="img"
            src={PopupActionCardDetails.image}
            sx={{
              height: { xs: "175px", lg: "350px" },
              padding: "8px",
              paddingTop: `${
                PopupActionCardDetails.title === "SALARY INCREASE"
                  ? "17px"
                  : "10px"
              }`,
            }}
            alt=""
          />

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                setPlayStockBlockBool(false);
                setOpen(false);
                if (player.type === "player") {
                  setPickUpActionCard(false);
                }
              }}
              // variant="contained"
              id="myButton"
              sx={{
                border: "0px",
                width: { sm: 50, lg: 109 },
                height: { sm: 16, lg: 37 },
                background: "#FFC700",
                boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 20px",
                cursor: "pointer",
                color: "#2C2C2C",
                fontSize: { sm: "10px", lg: "14px" },
                fontWeight: 700,
                "&:hover": {
                  backgroundColor: "#FFC700",
                },
              }}
            >
              CONTINUE
            </Button>
            {player.type !== "player" &&
              playerHasActionCard(0, "STOCK BLOCK") && (
                <Button
                  sx={{
                    width: { sm: 170, lg: 168 },
                    height: { sm: 16, lg: 37 },

                    background: " #FF3D00",

                    boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.1)",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 20px",
                    border: "0px",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    fontSize: ` ${
                      PopupActionCardDetails.title === "PERFORMANCE BONUS"
                        ? { xs: "7px", lg: "11px" }
                        : { xs: "10px", lg: "14px" }
                    }`,
                    fontWeight: 700,
                    fontFamily: "Urbanist , sans-serif",
                    fontStyle: "normal",
                    "&:hover": {
                      backgroundColor: "#FF3D00",
                    },
                  }}
                  onClick={() => {
                    setOpen(false);
                    setPlayStockBlockBool(true);
                    playStockBlock(0);
                  }}
                >
                  PLAY STOCK BLOCK
                </Button>
              )}
          </div>
        </Paper>
      </Slide>
    </Modal>
  );
}
