import { Container, useMediaQuery } from "@mui/material";
import Card from "./Cards/Card";
import { ActionCardModel } from "../shared/models/ActionCardModel";

const PlayerDeck = ({
  actionCards,
  playable,
  playerTurn,
}: {
  actionCards: Array<ActionCardModel>;
  playable: boolean;
  playerTurn: number;
}) => {
  const isMobile = useMediaQuery("(max-width:1000px)");

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        marginLeft: isMobile ? "25px" : "50px",
      }}
    >
      {/* Map player's action cards and show here */}
      {/* The ActionCard component will be used here, not on the action deck */}
      {actionCards.map((card: ActionCardModel, index: number) => (
        <Card
          key={card.title}
          card={card}
          index={index}
          playable={
            (playable && card.title !== "STOCK BLOCK") ||
            (card.title === "STOCK BLOCK" && playerTurn !== 0)
          }
        />
      ))}
    </Container>
  );
};

export default PlayerDeck;
