import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import money from "../assets/money 1.png";
import { PlayerModel } from "../shared/models/PlayerModel";
import { StockModel } from "../shared/models/StockModel";

const PortFolio = ({
  stockPrices,
  playerInfo,
  small,
  players,
  calculateNetWorth,
  higLightGrid,
  openWalkthroughModal,
}: {
  stockPrices: StockModel;
  playerInfo: PlayerModel;
  small?: boolean;
  players?: Array<PlayerModel>;
  calculateNetWorth?: (profileInfo: PlayerModel) => number;
  higLightGrid: string;
  openWalkthroughModal: boolean;
}) => {
  function createData(
    price: number,
    shares: number,
    industry: string,
    industry_short: string,
    total: number
  ) {
    return { price, industry, industry_short, shares, total };
  }

  const rows = [
    createData(
      stockPrices.technology.price,
      playerInfo.portfolio.technology,
      "TECHNOLOGY",
      "TECH",
      playerInfo.portfolio.technology * stockPrices.technology.price
    ),
    createData(
      stockPrices.real_estate.price,
      playerInfo.portfolio.real_estate,
      "REAL ESTATE",
      "REAL",
      playerInfo.portfolio.real_estate * stockPrices.real_estate.price
    ),
    createData(
      stockPrices.healthcare.price,
      playerInfo.portfolio.healthcare,
      "HEALTHCARE",
      "HLTH",
      playerInfo.portfolio.healthcare * stockPrices.healthcare.price
    ),
    createData(
      stockPrices.finance.price,
      playerInfo.portfolio.finance,
      "FINANCE",
      "FINN",
      playerInfo.portfolio.finance * stockPrices.finance.price
    ),
    createData(
      stockPrices.energy.price,
      playerInfo.portfolio.energy,
      "ENERGY",
      "ENRG",
      playerInfo.portfolio.energy * stockPrices.energy.price
    ),
    createData(
      stockPrices.consumer_goods.price,
      playerInfo.portfolio.consumer_goods,
      "CONSUMER GOODS",
      "GOOD",
      playerInfo.portfolio.consumer_goods * stockPrices.consumer_goods.price
    ),
    createData(
      stockPrices.commodities.price,
      playerInfo.portfolio.commodities,
      "COMMODITIES",
      "COMD",
      playerInfo.portfolio.commodities * stockPrices.commodities.price
    ),
    createData(
      stockPrices.bonds.price,
      playerInfo.portfolio.bonds,
      "BONDS",
      "BOND",
      playerInfo.portfolio.bonds * stockPrices.bonds.price
    ),
  ];

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={{ marginTop: "1vh" }}>
          <Box
            sx={{
              display: "flex",
              paddingLeft: "25px",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: "25px", lg: "50px" },
                height: { xs: "25px", lg: "50px" },
                borderRadius: "50px",
              }}
              src={require("../assets/Avatar/" + playerInfo.name + ".png")}
            />
            <Typography
              sx={{
                paddingLeft: "13px",
                color: "#3F403A",
                fontWeight: 700,
                fontSize: { xs: "10px", lg: "20px" },
                fontFamily: "'Urbanist', sans-serif",
                fontStyle: "normal",
              }}
            >
              {playerInfo.type === "player"
                ? "YOUR PORTFOLIO"
                : playerInfo.name}
            </Typography>
            {small && (
              <Typography
                sx={{
                  paddingLeft: "10px",
                  zIndex: `${
                    higLightGrid === "cash" && openWalkthroughModal ? 9999 : 0
                  }`,
                  position: `${
                    higLightGrid === "cash" && openWalkthroughModal
                      ? "relative"
                      : "static"
                  }`,
                  background: `${
                    higLightGrid === "cash" && openWalkthroughModal
                      ? "#F2F5F9"
                      : ""
                  }`,
                  fontWeight: 700,
                  fontSize: { xs: 7, lg: 13 },
                  marginLeft: "7vw",
                }}
              >
                <span
                  style={{
                    paddingRight: "10px",
                    color: "#3F403A",
                  }}
                >
                  CASH:
                </span>
                <span style={{ color: "#001D3D" }}>
                  ${playerInfo.cash / 1000000} <span>M</span>
                </span>
              </Typography>
            )}
          </Box>
          {!small && (
            <div
              style={{
                display: "flex",
                paddingLeft: "25px",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: 11, lg: 22 },
                  height: { xs: 11, lg: 20 },
                  zIndex: `${
                    higLightGrid === "cash" && openWalkthroughModal ? 9999 : 0
                  }`,
                  position: `${
                    higLightGrid === "cash" && openWalkthroughModal
                      ? "relative"
                      : "static"
                  }`,
                  background: `${
                    higLightGrid === "cash" && openWalkthroughModal
                      ? "#F2F5F9"
                      : ""
                  }`,
                }}
                src={money}
                alt=""
              />
              <Typography
                sx={{
                  paddingLeft: "10px",
                  zIndex: `${
                    higLightGrid === "cash" && openWalkthroughModal ? 9999 : 0
                  }`,
                  position: `${
                    higLightGrid === "cash" && openWalkthroughModal
                      ? "relative"
                      : "static"
                  }`,
                  background: `${
                    higLightGrid === "cash" && openWalkthroughModal
                      ? "#F2F5F9"
                      : ""
                  }`,
                  fontWeight: 700,
                  fontSize: { xs: 7, lg: 13 },
                }}
              >
                <span
                  style={{
                    paddingRight: "10px",
                    color: "#3F403A",
                  }}
                >
                  CASH:
                </span>
                <span style={{ color: "#001D3D" }}>
                  ${playerInfo.cash / 1000000} <span>M</span>
                </span>
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <hr
        style={{
          width: "93%",
        }}
        color="lightgrey"
      />
      <TableContainer sx={{ overflowY: "scroll", height: "79%" }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {!small && (
                <TableCell sx={{ borderBottom: "none" }}>
                  <Typography
                    sx={{
                      color: "#3F403A",
                      fontSize: { xs: "6px", lg: "12px" },
                      fontWeight: 600,
                      fontFamily: "'Urbanist', sans-serif",
                      fontStyle: "italic",
                    }}
                  >
                    {" "}
                    PRICE
                  </Typography>
                </TableCell>
              )}
              <TableCell sx={{ borderBottom: "none" }}>
                <Typography
                  sx={{
                    color: "#3F403A",
                    fontSize: { xs: "6px", lg: "12px" },
                    fontWeight: 600,
                    fontFamily: "'Urbanist', sans-serif",
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  INDUSTRY
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>
                <Typography
                  sx={{
                    color: "#3F403A",
                    fontSize: { xs: "6px", lg: "12px" },
                    fontWeight: 600,
                    fontFamily: "'Urbanist', sans-serif",
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  SHARES
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>
                <Typography
                  sx={{
                    color: "#3F403A",
                    fontSize: { xs: "6px", lg: "12px" },
                    fontWeight: 600,
                    fontFamily: "'Urbanist', sans-serif",
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  TOTAL VALUE
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow key={row.industry}>
                {!small && (
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: "20%", borderBottom: "none" }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "7px", lg: "14px" },
                        fontWeight: 700,
                        color: "#001D3D",
                        fontFamily: "'Urbanist', sans-serif",
                      }}
                    >
                      ${row.price / 1000000} <span>M</span>
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  component="th"
                  sx={{ width: small ? "15%" : "100%", borderBottom: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={require("../assets/IndustryIcons/" +
                        row.industry +
                        ".png")}
                      sx={{
                        width: { xs: "10px", lg: "30px" },
                        height: { xs: "10px", lg: "30px" },
                      }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          paddingLeft: { xs: "3px", lg: "10px" },
                          fontSize: { xs: "6px", lg: "14px" },
                          fontWeight: 700,
                          color: "#3F403A",
                          fontFamily: "'Urbanist', sans-serif",
                          fontStyle: "normal",
                        }}
                      >
                        {row.industry}
                      </Typography>
                      {!small && (
                        <Typography
                          sx={{
                            display: { xs: "none", lg: "inherit" },
                            paddingLeft: "10px",
                            fontSize: { xs: "5px", lg: "10px" },
                            color: "#3F403A",
                            fontFamily: "Minecraft",
                            fontStyle: "normal",
                          }}
                        >
                          {row.industry_short}
                        </Typography>
                      )}
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  // sx={{
                  //   display: "flex",
                  // }}
                  sx={{ borderBottom: "none" }}
                >
                  <Typography
                    sx={{
                      color: "#001D3D",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: { xs: "5px", lg: "11px" },
                      fontWeight: 700,
                      fontFamily: "'Urbanist', sans-serif",
                    }}
                  >
                    {row.shares}
                  </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "5px", lg: "11px" },
                      fontWeight: 700,
                      color: "#001D3D",
                      fontFamily: "'Urbanist', sans-serif",
                    }}
                  >
                    ${row.total / 1000000} <span>M</span>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PortFolio;
