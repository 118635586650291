import React from "react";
import { PlayerModel } from "../../shared/models/PlayerModel";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";

const PlayerSelectionModal = ({
  players,
  selectPlayerModelOpen,
  setSelectPlayerModelOpen,
  setSelectedActionPlayer,
}: {
  players: Array<PlayerModel>;
  selectPlayerModelOpen: boolean;
  setSelectPlayerModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedActionPlayer: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  return (
    <Modal
      open={selectPlayerModelOpen}
      onClose={() => setSelectPlayerModelOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableAutoFocus={true}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          width: { xs: 250, lg: 500 },
          borderRadius: "md",
          p: { sm: 1, lg: 5 },
          boxShadow: "lg",
          background: "#F2F5F9",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setSelectPlayerModelOpen(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "gray",
          }}
        >
          <MdClose size={"3vh"} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: 700, fontSize: { xs: "10px", lg: "20px" } }}
            id="modal-title"
            fontWeight="lg"
            mb={1}
          >
            Select A Player
          </Typography>

          <Typography
            sx={{ fontWeight: 500, fontSize: { xs: "8px", lg: "16px" } }}
          >
            Which player would you like to select?
          </Typography>
          <Grid
            container
            sx={{ marginTop: "1vh", paddingLeft: { xs: "10px", lg: "50px" } }}
          >
            {players.map(
              (_item: any, index: number) =>
                index !== 0 && (
                  <Button
                    key={_item.name}
                    onClick={() => {
                      setSelectedActionPlayer(index);
                      setSelectPlayerModelOpen(false);
                    }}
                    sx={{
                      width: { sm: 100, lg: 200 },
                      height: { sm: 30, lg: 60 },
                      background: "#FFFFFF",
                      border: {
                        xs: "1px solid #001D3D",
                        lg: "2px solid #001D3D",
                      },
                      borderRadius: { sm: 1.5, lg: 3 },
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: "5px",
                    }}
                  >
                    <Box
                      component="img"
                      src={require(`../../assets/Avatar/` +
                        _item.name +
                        ".png")}
                      sx={{
                        height: { sm: "24px", lg: "48px" },
                        width: { sm: "24px", lg: "48px" },
                      }}
                      alt=""
                    />
                    <Typography
                      sx={{
                        paddingLeft: { xs: "5px", lg: "10px" },
                        color: "#2C2C2C",
                        fontFamily: " 'Urbanist', sans-serif",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: { xs: "10px", lg: "20px" },
                      }}
                    >
                      {_item.name}
                    </Typography>
                  </Button>
                )
            )}
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default PlayerSelectionModal;
