import { Button, Container, Typography } from "@mui/material";
import { Mixpanel } from "../helpers/mixpanel-helper";

const BuySellEndTurn = ({
  players,
  playerTurn,
  setSelectedNoOfShares,
  setBuySellModalOpen,
  handleClick,
  setPlayers,
  diceClick,
  setTurnOverForPlayer,
  openResultModal,
  setSlideToOpen,
  setOpenWalkthroughModal,
  walkthroughActive,
}: any) => {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        background: ` ${
          playerTurn > 0
            ? "#C32F00"
            : "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 86.08%, rgba(255, 255, 255, 0) 100%), #677585"
        }`,
      }}
    >
      {playerTurn === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
            }}
          >
            MOVES: {3 - players[0].moves}/3
          </Typography>
          <Button
            sx={{
              minWidth: 0,
              width: { xs: 40, lg: 70 },
              height: { xs: 20, lg: 35 },
              backgroundColor: "#F2F5F9",
              boxShadow: "0px 3.71667px 9.29167px rgba(53, 53, 53, 0.1)",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              color: "black",
              opacity: `${
                players[playerTurn].cash === 0 ||
                playerTurn === 1 ||
                playerTurn === 2 ||
                playerTurn === 3 ||
                !diceClick
                  ? "0.4"
                  : ""
              }`,
              "&:hover": {
                backgroundColor: "#F2F5F9",
              },
            }}
            onClick={(event) => {
              if (players[playerTurn].moves !== 0 && diceClick) {
                setSelectedNoOfShares(1);
                setBuySellModalOpen(true);
                handleClick(event);

                if (walkthroughActive) {
                  setOpenWalkthroughModal(true);
                  setSlideToOpen(10);
                }
              }
              if (players[playerTurn].cash === 0) {
                setBuySellModalOpen(false);
              }
              if (playerTurn === 1 || playerTurn === 2 || playerTurn === 3) {
                setBuySellModalOpen(false);
              }
            }}
          >
            BUY
          </Button>
          <p
            style={{
              color: "#AF0000",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: " 12px",
              marginLeft: "-28px",
            }}
          ></p>
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              width: { xs: 40, lg: 70 },
              height: { xs: 20, lg: 35 },
              background: "#F2F5F9",
              boxShadow: "0px 3.71667px 9.29167px rgba(53, 53, 53, 0.1)",
              borderRadius: " 5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              opacity: `${
                (players[playerTurn].portfolio.finance === 0 &&
                  players[playerTurn].portfolio.real_estate === 0 &&
                  players[playerTurn].portfolio.consumer_goods === 0 &&
                  players[playerTurn].portfolio.healthcare === 0 &&
                  players[playerTurn].portfolio.commodities === 0 &&
                  players[playerTurn].portfolio.energy === 0 &&
                  players[playerTurn].portfolio.bonds === 0 &&
                  players[playerTurn].portfolio.technology === 0) ||
                playerTurn === 1 ||
                playerTurn === 2 ||
                playerTurn === 3 ||
                !diceClick
                  ? "0.4"
                  : ""
              }`,
              "&:hover": {
                backgroundColor: "#F2F5F9",
              },
            }}
            onClick={(event) => {
              if (players[playerTurn].moves !== 0 && diceClick) {
                setSelectedNoOfShares(1);
                setBuySellModalOpen(true);
                handleClick(event);
              }
              if (
                players[playerTurn].portfolio.finance === 0 &&
                players[playerTurn].portfolio.real_estate === 0 &&
                players[playerTurn].portfolio.consumer_goods === 0 &&
                players[playerTurn].portfolio.healthcare === 0 &&
                players[playerTurn].portfolio.commodities === 0 &&
                players[playerTurn].portfolio.energy === 0 &&
                players[playerTurn].portfolio.bonds === 0 &&
                players[playerTurn].portfolio.technology === 0
              ) {
                setBuySellModalOpen(false);
              }

              if (playerTurn === 1 || playerTurn === 2 || playerTurn === 3) {
                setBuySellModalOpen(false);
              }
            }}
          >
            SELL
          </Button>
          <p
            style={{
              color: "#AF0000",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: " 12px",
              // position: "fixed",
              // paddingBottom: "50px",
              width: "",
              marginLeft: "-28px",
            }}
          ></p>
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              width: { xs: 60, lg: 99 },
              height: { xs: 20, lg: 34 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#FFC700",
              boxShadow: " 0px 3.71667px 9.29167px rgba(53, 53, 53, 0.1)",
              borderRadius: "5px",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              cursor: "pointer",
              opacity: `${
                playerTurn !== 1 &&
                playerTurn !== 2 &&
                playerTurn !== 3 &&
                diceClick
                  ? ""
                  : "0.4"
              }`,
              "&:hover": {
                backgroundColor: "#FFC700",
              },
            }}
            onClick={() => {
              if (
                playerTurn !== 1 &&
                playerTurn !== 2 &&
                playerTurn !== 3 &&
                diceClick &&
                walkthroughActive
              ) {
                setOpenWalkthroughModal(true);

                switch(players[0].moves) {
                  case 1:
                    setSlideToOpen(11);
                    break
                  case 2:
                    setSlideToOpen(12);
                    break
                  case 3:
                    setSlideToOpen(13);
                    break
                  default:
                    break
                }
              }
              
              Mixpanel.track("End Turn Clicked");
              if (
                playerTurn !== 1 &&
                playerTurn !== 2 &&
                playerTurn !== 3 &&
                diceClick &&
                !walkthroughActive
              ) {
                setPlayers((prevPlayers: any) => {
                  const newPlayers = [...prevPlayers];
                  newPlayers[playerTurn].moves = 0;
                  return newPlayers;
                });
                setTurnOverForPlayer(true);
              }
            }}
          >
            END TURN
          </Button>
        </div>
      ) : (
        <p
          style={{
            color: " #F2F5F9",
            fontFamily: " 'Urbanist', sans-serif",
            fontStyle: "normal",
            fontSize: 20,
            lineHeight: 24,
          }}
        >
          {players[playerTurn].name + "'S TURN"}
        </p>
      )}
    </Container>
  );
};

export default BuySellEndTurn;
