import { Box, Modal, Paper, useMediaQuery, Button } from "@mui/material";
import React, { useEffect } from "react";
import { Mixpanel } from "../../helpers/mixpanel-helper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../Components/Carousel/Carousel.css";
import CloseIcon from "../../assets/ToolTips/Frame 173.png";
import NasDaq from "../../assets/Group 60.png";
import Cookies from "js-cookie";

const WalkThroughModal = ({
  setHighLightGrid,
  openWalkthroughModal,
  setOpenWalkthroughModal,
  user,
  handleFitstTime,
  highLightGrid,
  slideToOpen,
  playerTurn, 
  diceClick,
  setPlayers,
  setTurnOverForPlayer,
  setWalkthroughActive, 
  walkthroughActive,
}: any) => {
  const isMobile = useMediaQuery("(max-width:1000px)");

  const sliderRef = React.useRef<any>(null);

  const tourDialog = [
    `${require("../../assets/ToolTips/Slide0.png")}`,
    `${require("../../assets/ToolTips/Slide1.png")}`,
    `${require("../../assets/ToolTips/Slide2.png")}`,
    `${require("../../assets/ToolTips/Slide12.png")}`,
    `${require("../../assets/ToolTips/Slide13.png")}`,
    `${require("../../assets/ToolTips/Slide14.png")}`,
    `${require("../../assets/ToolTips/Slide3.png")}`,
    `${require("../../assets/ToolTips/Slide4.png")}`,
    `${require("../../assets/ToolTips/Slide5.png")}`,
    `${require("../../assets/ToolTips/Slide6.png")}`,
    `${require("../../assets/ToolTips/Slide7.png")}`,
    `${require("../../assets/ToolTips/Slide8.png")}`,
    `${require("../../assets/ToolTips/Slide9.png")}`,
    `${require("../../assets/ToolTips/Slide10.png")}`,
    `${require("../../assets/ToolTips/Slide11.png")}`,
    `${require("../../assets/ToolTips/Slide15.png")}`,
  ];

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handleAfterChange = (index: number) => {
    setCurrentSlide(index);
  };

  const handleBeforeChange = (current: number, next: number) => {
    setCurrentSlide(next);
  };

  const settings = {
    dots: isMobile ? false : false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: true,
    afterChange: handleAfterChange,
    slickNext: handleGridHighlight(currentSlide),
    beforeChange: handleBeforeChange,

    CenterMode: true,
    arrows: false,
    accessibility: true,
    slickPrev: true,
    dotsClass: "slick-dots",
    leftKey: "ArrowLeft",
    rightKey: "ArrowRight",
  };

  function handleGridHighlight(currentSlide: number) {
    if (currentSlide === 0) {
      setHighLightGrid("");
    } else if (currentSlide === 1) {
      setHighLightGrid("infoIcon");
    } else if (currentSlide === 2) {
      setHighLightGrid(""); 
    } else if (currentSlide === 3) {
      setHighLightGrid("");
    } else if (currentSlide === 4) {
      setHighLightGrid("botActionCards");
    } else if (currentSlide === 5) {
      setHighLightGrid("botTrackables");
    } else if (currentSlide === 6) {
      setHighLightGrid("");
    } else if (currentSlide === 7) {
      setHighLightGrid("dice");
    } else if (currentSlide === 8) {
      setHighLightGrid("playerDeck");
    } else if (currentSlide === 9) {
      setHighLightGrid("buySell");
    } else if (currentSlide === 10) {
      setHighLightGrid("buyMenu");
    } else if (currentSlide === 11) {
      setHighLightGrid("");
    } else if (currentSlide === 12) {
      setHighLightGrid("");
    } else if (currentSlide === 13) {
      setHighLightGrid("");
    } else if (currentSlide === 14) {
      setHighLightGrid("roundEventCard");
    } else if (currentSlide === 15) {
      setHighLightGrid(""); 
    } else if (!openWalkthroughModal) {
      setHighLightGrid("");
    }
  }

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    // const handleKeyPress = (e: any) => {
    //   if (e.key === "ArrowLeft") {
    //     previousSlide();
    //   } else if (e.key === "ArrowRight") {
    //     nextSlide();
    //   }
    // };

    // document.addEventListener("keydown", handleKeyPress);

    // return () => {
    //   document.removeEventListener("keydown", handleKeyPress);
    // };

    setCurrentSlide(slideToOpen);
  }, [slideToOpen]);

  return (
    <Modal
      open={openWalkthroughModal}
      onClose={undefined}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: `${currentSlide === 10 || currentSlide === 14 ? "98%" : "55%"}`,
        marginTop: `${currentSlide === 4 || currentSlide === 5 ? "20%" : "10%"}`,
        zIndex: 9998,
      }}
      disableAutoFocus={true}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: `${
              (highLightGrid === "buyMenu" || highLightGrid === "roundEventCard") && openWalkthroughModal ? "rgba(0,0,0,0)" : "rgba(0,0,0,0.5)"
            }`,
            pointerEvents: `${
              currentSlide === 10 || currentSlide === 14 ? "none" : ""
            }`,
          },
        },
      }}
    >
      <Paper
        sx={{
          width: { xs: "500px", lg: "1000px" },
          backgroundColor: "rgba(0,0,0,0)",
          display: "flex",
          position: "relative",
          zIndex: 9999,
          boxShadow: "none",
        }}
      >
        <Box
          component="img"
          src={require("../../assets/ToolTips/Big Tech Breakup Card 1.png")}
          alt=""
          sx={{
            display: `${currentSlide === 999 ? "block" : "none"}`,
            height: { xs: "170px", lg: "340px" },
            width: { xs: "125px", lg: "250px" },
            position: "absolute",
            bottom: "40%",
            left: "-58%",
          }}
        />

        <Box
          component="img"
          src={tourDialog[currentSlide]}
          alt=""
          sx={{
            width: { xs: "100px", lg: "40vh" },
            position: "absolute",
            bottom: `${currentSlide === 8 ? "50%" : "45%"}`,
            left: { xs: "10%", lg: "10vh" },
            zIndex: 9999,
          }}
        />

        <Box
          // box for buttons
          sx={{
            display: "flex",
            position: "absolute",
            left: "30%",
            bottom: "5%",
            gap: "5%",
            zIndex: 9999,
          }}
        >
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              display: `${currentSlide === 0 || currentSlide === 11 || currentSlide === 12 || currentSlide === 13 ? "flex" : "none"}`,
              width: { xs: 60, lg: 99 },
              height: { xs: 20, lg: 34 },
              justifyContent: "center",
              alignItems: "center",
              background: "#2bff2b",
              borderRadius: "5px",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#24d424",
              },
            }}
            onClick={() => {
              setWalkthroughActive(true); 
              if (currentSlide === 0) {
                setCurrentSlide(2);
              } else {
                setOpenWalkthroughModal(false);
                Mixpanel.track("End Turn Clicked");
                if (
                  playerTurn !== 1 &&
                  playerTurn !== 2 &&
                  playerTurn !== 3 &&
                  diceClick
                ) {
                  setPlayers((prevPlayers: any) => {
                    const newPlayers = [...prevPlayers];
                    newPlayers[playerTurn].moves = 0;
                    return newPlayers;
                  });
                  setTurnOverForPlayer(true);
                }
              }
            }}
          >
            Yes
          </Button>
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              display: `${currentSlide === 0 || currentSlide === 11 || currentSlide === 12 || currentSlide === 13 ? "flex" : "none"}`,
              width: { xs: 60, lg: 99 },
              height: { xs: 20, lg: 34 },
              justifyContent: "center",
              alignItems: "center",
              background: "#ff0015",
              borderRadius: "5px",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#d10011",
              },
            }}
            onClick={() => {
              if (currentSlide === 0) {
                setCurrentSlide(1);
              } else {
                setOpenWalkthroughModal(false);
              }
            }}
          >
            No
          </Button>
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              display: `${currentSlide === 1 ? "flex" : "none"}`,
              width: { xs: 60, lg: 99 },
              height: { xs: 20, lg: 34 },
              left: "50%",
              justifyContent: "center",
              alignItems: "center",
              background: "#FFFFFF",
              borderRadius: "5px",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#c3c5c7",
              },
            }}
            onClick={() => {
              setOpenWalkthroughModal(false);
              setHighLightGrid("");
              handleFitstTime(user);
              setCurrentSlide(0);
            }}
          >
            Close
          </Button>
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              display: `${ currentSlide === 2 || currentSlide === 3 || currentSlide === 4 || currentSlide === 5 || currentSlide === 6 || currentSlide === 8 ? "flex" : "none"}`,
              width: { xs: 60, lg: 99 },
              height: { xs: 20, lg: 34 },
              left: "50%",
              justifyContent: "center",
              alignItems: "center",
              background: "#ffffff",
              borderRadius: "5px",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#c3c5c7",
              },
            }}
            onClick={() => {
              setCurrentSlide(currentSlide + 1);
            }}
          >
            Next
          </Button>
          <Button
            sx={{
              color: "black",
              minWidth: 0,
              display: `${currentSlide === 15 ? "flex" : "none"}`,
              left: "50%",
              width: { xs: 60, lg: 99 },
              height: { xs: 20, lg: 34 },
              justifyContent: "center",
              alignItems: "center",
              background: "#2bff2b",
              borderRadius: "5px",
              fontFamily: " 'Urbanist', sans-serif",
              fontStyle: "normal",
              fontSize: { xs: "7px", lg: "14px" },
              fontWeight: 700,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#24d424",
              },
            }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Go
          </Button>
        </Box>

        <Box
          sx={{
            width: { xs: "20%", lg: "30%" },
            marginLeft: { xs: "125px", lg: "30vh" },
            marginBottom: { xs: "100px", lg: "10vh" },
          }}
        >
          <Slider {...settings} ref={sliderRef}>
            {Array.from({ length: 20 }).map((_, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={NasDaq}
                  alt=""
                  sx={{
                    width: { xs: "60px", lg: "20vh" },
                    margin: "auto",
                  }}
                />
              </div>
            ))}
          </Slider>
        </Box>
        {/* <Box */}
        {/*   component="img" */}
        {/*   src={CloseIcon} */}
        {/*   alt="" */}
        {/*   sx={{ */}
        {/*     width: { xs: 16, lg: 32 }, */}
        {/*     height: { xs: 20.5, lg: 41 }, */}
        {/*     cursor: "pointer", */}
        {/*     marginTop: "30px", */}
        {/*   }} */}
        {/*   onClick={() => { */}
        {/*     setOpenWalkthroughModal(false); */}
        {/*     setHighLightGrid(""); */}
        {/*     handleFitstTime(user); */}
        {/*   }} */}
        {/*   // onClick={() => { */}
        {/*   //   setOpenWalkthroughModal(false); */}
        {/*   // }} */}
        {/* /> */}
      </Paper>
    </Modal>
  );
};

export default WalkThroughModal;
