import { Box } from "@mui/system";

import { Button, IconButton, Modal, Paper, Typography } from "@mui/material";
import { MdArrowBack, MdClose } from "react-icons/md";
import { Mixpanel } from "../../helpers/mixpanel-helper";

export default function Confirmation({
  open,
  setOpen,
  modaltype,
  playerShares,
  selectedNoOfShares,
  share,
  setQtyModalOpen,
  buyShares,
  sellShares,
}: any) {
  function previousModal() {
    setOpen(false);
    setQtyModalOpen(true);
  }
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableAutoFocus={true}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          width: { xs: 250, lg: 500 },
          borderRadius: "md",
          p: { sm: 1, lg: 5 },
          boxShadow: "lg",
          background: "#F2F5F9",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "gray",
          }}
        >
          <MdClose size={"3vh"} />
        </IconButton>
        <IconButton
          onClick={previousModal}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            color: "gray",
          }}
        >
          <MdArrowBack size={"3vh"} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",

            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={require("../../assets/IndustryIcons/" +
              share.name.toUpperCase() +
              ".png")}
            sx={{
              height: { sm: "12px", lg: "24px" },
              width: { sm: "12px", lg: "24px" },
            }}
            alt=""
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { sm: "10px", lg: "20px" },
              marginTop: "1vh",
            }}
          >
            {modaltype === "BUY" ? "Buy" : "Sell"} - {share.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "8px", lg: "16px" },
              marginTop: "1vh",
            }}
          >
            Shares owned: {playerShares}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "8px", lg: "16px" },
              marginTop: "1vh",
            }}
          >
            {modaltype === "BUY" ? "Buying" : "Selling"} {selectedNoOfShares}{" "}
            {selectedNoOfShares === 1 ? "share" : "shares"} at ${" "}
            {share.price / 1000000}M
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "8px", lg: "16px" },
              marginTop: "1vh",
            }}
          >
            {modaltype === "BUY" ? "Total: $ " : "Cash Proceeds: $ "}
            {modaltype === "BUY"
              ? ((playerShares + selectedNoOfShares) * share.price) / 1000000
              : (selectedNoOfShares * share.price) / 1000000}
            M
          </Typography>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "77px", lg: "150px" },
              height: { xs: "20px", lg: "37px" },
              color: "#2C2C2C",
              background: "#FFC700",
              border: "2px solid #9C7A00",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "1vh",
              fontSize: { xs: "8px", lg: "16px" },
            }}
            onClick={() => {
              if (modaltype === "BUY") {
                Mixpanel.track(share.name + " Shares Bought");
                buyShares(selectedNoOfShares);
              } else {
                Mixpanel.track("Shares Sold");
                sellShares(selectedNoOfShares);
              }
              setOpen(false);
            }}
          >
            CONFIRM {modaltype === "BUY" ? "BUY" : "SELL"}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
